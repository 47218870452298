import { v4 as uuid } from 'uuid'
import { ColumnTypeEnum } from 'common/components/entities/Column/enums/ColumnTypeEnum'
import { redirectionTypes } from 'common/constants/buttonTypes'
import { buttonActionsTypes } from 'common/constants/settings'
import structureTypes from 'common/constants/structureTypes'
import { FieldSlugEnum } from 'common/enums/FieldSlugEnum'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { EntitiesStructure } from 'common/types/Page'
import EntityInterface, {
  BaseEntityWithChildIdsInterface,
  EntityInnerItemInterface,
  EntityWithChildIdsInterface,
} from 'common/types/entities/EntityInterface'
import {
  OldEntityInterface,
  OldEntityWithChildIds,
} from 'common/types/entities/OldEntityInterface'
import { createCheckbox } from 'client/components/core/Sidebar/components/Settings/entities/CheckboxSettings/utils/create-checkbox'
import { createContentBox } from 'client/components/core/Sidebar/components/Settings/entities/ContentBoxSettings/utils/create-content-box'
import { createCountdown } from 'client/components/core/Sidebar/components/Settings/entities/Countdown/utils/create-countdown'
import { createBulletList } from 'client/components/core/Sidebar/components/Settings/entities/NewBulletListSettings/utils/create-bullet-list'
import { createColumn } from 'client/components/core/Sidebar/components/Settings/entities/RowSettings/utils/create-column'
import { createRowColumnLayout } from 'client/components/core/Sidebar/components/Settings/entities/RowSettings/utils/create-row-column-layout'
import { createAudio } from 'client/components/entities/Audio/AudioNew'
import { createFacebookComments } from 'client/components/entities/FacebookComments/utils/create-facebook-comments'
import { createMenu } from 'client/components/entities/Menu/Menu'
import { createHorizontalLine } from 'client/components/entities/NewHorizontalLine'
import { createOrderSummary } from 'client/components/entities/OfferThankYouOrderSummaryNew'
import { createRecaptcha } from 'client/components/entities/Recaptcha'
import { createSection } from 'client/components/entities/Section/utils/createSection'
import { createTwitterTweetButton } from 'client/components/entities/TwitterTweetButton/utils/create-twitter-tweet-button'
import { createVideo } from 'client/components/entities/VideoNew'
import i18n from 'client/i18n'
import { createBlogLatestPosts } from 'client/pages/blog-page/entities/BlogLatestPosts'
import { createBlogPostListing } from 'client/pages/blog-page/entities/BlogPostListing'
import { createBlogContentPlaceholder } from 'client/pages/blog/entities/BlogContentPlaceholderNew'
import { createBlogPostCategories } from 'client/pages/blog/entities/BlogPostCategories'
import { createBlogPostContentPlaceholder } from 'client/pages/blog/entities/BlogPostContentPlaceholderNew'
import { createBlogPostDate } from 'client/pages/blog/entities/BlogPostDateNew'
import { createBlogPostTitle } from 'client/pages/blog/entities/BlogPostTItleNew'
import { createAttachments } from 'client/pages/contact-us/entities/Attachments'
import { createNewCustomLogin } from 'client/pages/custom-login/entities/CustomLogin'
import { createCustomerType } from 'client/pages/offer/entities/CustomerType'
import { createPaymentMethod } from 'client/pages/offer/entities/PaymentMethod'
import { createSalesRedirectionButton } from 'client/pages/sales/entities/SalesRedirectionButton'
import {
  createUpsellAgreeButton,
  createUpsellDisagreeButton,
} from 'client/pages/upsell/entities/UpsellButton'
import { createWebinarCallToAction } from 'client/pages/webinar-session/entities/WebinarCallToAction'
import { createWebinarSessionVideo } from 'client/pages/webinar-session/entities/WebinarSessionVideo'
import { createWebinarSessionDateTime } from 'client/pages/webinar-thank-you/entities/WebinarSessionDateTime'
import { createWebinarSessionLink } from 'client/pages/webinar-thank-you/entities/WebinarSessionLink'
import {
  createCarousel,
  createCarouselItem,
} from '../components/entities/Carousel'
import { createBlogPostContentTable } from '../components/entities/ContentTable'
import { createFaq, createFaqAnswer } from '../components/entities/Faq'
import { createHeadline } from '../components/entities/Headline'
import { createImage } from '../components/entities/Image/Image'
import { createLanguageSwitcher } from '../components/entities/LanguageSwitcher/NewLanguageSwitcher'
import { createPricePlanCalculator } from '../components/entities/PricePlanCalculator'
import { createSurvey } from '../components/entities/Survey/Survey'
import { createText } from '../components/entities/Text/Text'
import { defaultStyles } from '../constants/editorSettings'
import { createBlogCategoryDescription } from '../pages/blog-page/entities/BlogCategoryDescription'
import { createBlogCategoryTitle } from '../pages/blog-page/entities/BlogCategoryTitle'
import { createBlogPostImage } from '../pages/blog/entities/BlogPostImage'
import { createBreadcrumbs } from '../pages/blog/entities/Breadcrumbs'
import { createContactUsButton } from '../pages/contact-us/entities/Button'
import { createContactUs } from '../pages/contact-us/entities/ContactUs'
import { createContactUsField } from '../pages/contact-us/entities/ContactUsField'
import { createTextArea } from '../pages/contact-us/entities/TextArea'
import { createForgotPassword } from '../pages/custom-login/entities/ForgotPassword'
import { createWebinarRegistrationDate } from '../pages/webinar-registration-page/entities/WebinarRegistrationDate'
import * as createStructureOld from './createStructureNew'
import { createRawHtml } from '../components/entities/RawHtml/NewRawHtml'
import { createShippingFees } from 'client/components/entities/ShippingFees'

export const generateId = () => uuid()

export function generateBaseEntity(
  type: EntityTypeEnum,
  parentId: string,
  masterBlockId?: string,
) {
  const baseEntity: Omit<EntityInterface, 'type'> = {
    id: generateId(),
    parentId: parentId,
    margin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    mobileMargin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    appearance: {
      desktop: true,
      mobile: true,
    },
    htmlAttrId: generateHtmlAttrId(type),
  }

  if (masterBlockId) {
    baseEntity.masterBlockId = masterBlockId
  }

  return baseEntity
}

export type Structure = Record<
  string,
  OldEntityInterface | EntityInterface | EntityInnerItemInterface
>

export function createCarouselFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
  masterBlockId?: string,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const carousel = createCarousel(container.id, masterBlockId)
  const carouselItem = createCarouselItem(carousel.id, masterBlockId)

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(descendantEntity.childIds, carousel.id, position),
    },
    [carousel.id]: {
      ...carousel,
      parentId: descendantEntity.id,
      childIds: [carouselItem.id],
    },
    [carouselItem.id]: { ...carouselItem, parentId: carousel.id },
  }
}

export function createPricePlanCalculatorFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
  masterBlockId?: string,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const pricePlanCalculator = createPricePlanCalculator(
    container.id,
    masterBlockId,
  )

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        pricePlanCalculator.id,
        position,
      ),
    },
    [pricePlanCalculator.id]: {
      ...pricePlanCalculator,
      parentId: descendantEntity.id,
    },
  }
}

export function createOrderSummaryFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
  masterBlockId?: string,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const offerThankYouOrderSummary = createOrderSummary(
    container.id,
    masterBlockId,
  )

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        offerThankYouOrderSummary.id,
        position,
      ),
    },
    [offerThankYouOrderSummary.id]: {
      ...offerThankYouOrderSummary,
      parentId: descendantEntity.id,
    },
  }
}

export function createBlogPostListingFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
  masterBlockId?: string,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const blogPostListing = createBlogPostListing(container.id, masterBlockId)
  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        blogPostListing.id,
        position,
      ),
    },
    [blogPostListing.id]: {
      ...blogPostListing,
      parentId: descendantEntity.id,
    },
  }
}

export function createBlogLatestPostsFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
  masterBlockId?: string,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const blogLatestPosts = createBlogLatestPosts(container.id, masterBlockId)
  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        blogLatestPosts.id,
        position,
      ),
    },
    [blogLatestPosts.id]: {
      ...blogLatestPosts,
      parentId: descendantEntity.id,
    },
  }
}

export function createBlogPostDateFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
  masterBlockId?: string,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const blogPostDate = createBlogPostDate(container.id, masterBlockId)
  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        blogPostDate.id,
        position,
      ),
    },
    [blogPostDate.id]: {
      ...blogPostDate,
      parentId: descendantEntity.id,
    },
  }
}

export function createBlogPostTitleFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
  masterBlockId?: string,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const blogPostTitle = createBlogPostTitle(container.id, masterBlockId)

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        blogPostTitle.id,
        position,
      ),
    },
    [blogPostTitle.id]: {
      ...blogPostTitle,
      parentId: descendantEntity.id,
    },
  }
}

export function createWebinarRegistrationDateFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const webinarRegistrationDate = createWebinarRegistrationDate(
    container.id,
    container.masterBlockId,
  )

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        webinarRegistrationDate.id,
        position,
      ),
    },
    [webinarRegistrationDate.id]: {
      ...webinarRegistrationDate,
      parentId: descendantEntity.id,
    },
  }
}

export function createImageFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const image = createImage(container.id, container.masterBlockId)

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(descendantEntity.childIds, image.id, position),
    },
    [image.id]: {
      ...image,
      parentId: descendantEntity.id,
    },
  }
}

export function createFaqFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const faq = createFaq(container.id, container.masterBlockId)
  const fixedT = i18n.getFixedT(locale)
  const faqAnswer = createFaqAnswer(faq.id, container.masterBlockId)
  faqAnswer.title = fixedT('entity_settings.faq_item.default_title')

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(descendantEntity.childIds, faq.id, position),
    },
    [faq.id]: {
      ...faq,
      parentId: descendantEntity.id,
      childIds: [faqAnswer.id],
    },
    [faqAnswer.id]: { ...faqAnswer, parentId: faq.id },
  }
}

export function createTextFromLibrary(
  container: OldEntityWithChildIds | EntityInnerItemInterface,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const text = createText(container.id, container.masterBlockId)

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(descendantEntity.childIds, text.id, position),
    },
    [text.id]: {
      ...text,
      parentId: descendantEntity.id,
    },
  }
}

export function createHeadlineFromLibrary(
  container: OldEntityWithChildIds | EntityInnerItemInterface,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const fixedT = i18n.getFixedT(locale)
  const headline = createHeadline(
    container.id,
    fixedT('entities.text_headline.default_text'),
    container.masterBlockId,
  )

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(descendantEntity.childIds, headline.id, position),
    },
    [headline.id]: {
      ...headline,
      parentId: descendantEntity.id,
    },
  }
}

export function createWebinarSessionLinkFromLibrary(
  container: OldEntityWithChildIds | EntityInnerItemInterface,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const webinarSessionLink = createWebinarSessionLink(
    container.id,
    container.masterBlockId,
  )

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        webinarSessionLink.id,
        position,
      ),
    },
    [webinarSessionLink.id]: {
      ...webinarSessionLink,
      parentId: descendantEntity.id,
    },
  }
}

export function createWebinarSessionDateFromLibrary(
  container: OldEntityWithChildIds | EntityInnerItemInterface,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const webinarSessionDate = createWebinarSessionDateTime(
    container.id,
    container.masterBlockId,
  )

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        webinarSessionDate.id,
        position,
      ),
    },
    [webinarSessionDate.id]: {
      ...webinarSessionDate,
      parentId: descendantEntity.id,
    },
  }
}

export function createUpsellAgreeButtonFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
  masterBlockId?: string,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const fixedT = i18n.getFixedT(locale)
  const upsellAgreeButton = createUpsellAgreeButton(container.id, masterBlockId)
  upsellAgreeButton.text = fixedT('entities.payment_button_agree.default_text')
  upsellAgreeButton.subText = fixedT('entities.button.default_sub_text')

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        upsellAgreeButton.id,
        position,
      ),
    },
    [upsellAgreeButton.id]: {
      ...upsellAgreeButton,
      parentId: descendantEntity.id,
    },
  }
}

export function createUpsellDisagreeButtonFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
  masterBlockId?: string,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const fixedT = i18n.getFixedT(locale)
  const upsellDisagreeButton = createUpsellDisagreeButton(
    container.id,
    masterBlockId,
  )
  upsellDisagreeButton.text = fixedT(
    'entities.payment_button_disagree.default_text',
  )
  upsellDisagreeButton.subText = fixedT('entities.button.default_sub_text')

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        upsellDisagreeButton.id,
        position,
      ),
    },
    [upsellDisagreeButton.id]: {
      ...upsellDisagreeButton,
      parentId: descendantEntity.id,
    },
  }
}

export function createSalesRedirectionButtonFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
  masterBlockId?: string,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const fixedT = i18n.getFixedT(locale)
  const salesRedirectionButton = createSalesRedirectionButton(
    container.id,
    masterBlockId,
  )
  salesRedirectionButton.text = fixedT(
    'entities.sales_redirection_button.default_text',
  )

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        salesRedirectionButton.id,
        position,
      ),
    },
    [salesRedirectionButton.id]: {
      ...salesRedirectionButton,
      parentId: descendantEntity.id,
    },
  }
}

export function createBlogPostCategoriesFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
  masterBlockId?: string,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const blogPostDate = createBlogPostCategories(container.id, masterBlockId)

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        blogPostDate.id,
        position,
      ),
    },
    [blogPostDate.id]: {
      ...blogPostDate,
      parentId: descendantEntity.id,
    },
  }
}

export function createBlogPostContentPlaceholderFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
  masterBlockId?: string,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const blogPostContentPlaceholder = createBlogPostContentPlaceholder(
    container.id,
    masterBlockId,
  )

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        blogPostContentPlaceholder.id,
        position,
      ),
    },
    [blogPostContentPlaceholder.id]: {
      ...blogPostContentPlaceholder,
      parentId: descendantEntity.id,
    },
  }
}

export function createCustomLoginFromLibrary(
  container: OldEntityWithChildIds | EntityInnerItemInterface,
  locale: string,
  position = 0,
  oldId?: string,
) {
  const fixedT = i18n.getFixedT(locale)

  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )

  const newCustomLogin = createNewCustomLogin(
    descendantEntity.id,
    descendantEntity.masterBlockId,
  )
  if (oldId) {
    newCustomLogin.id = oldId
  }
  const { column1: loginContainer } = createRowColumnLayout({
    parentEntity: newCustomLogin,
    position,
  })

  const loginHeaderText = fixedT(
    'entities.member_access.login.default_headline',
  )
  const firstLoginHeaderText = createText(
    loginContainer.id,
    container.masterBlockId,
  )
  firstLoginHeaderText.rawContentState = `{"blocks":[{"key":"7gjsu","text":"${loginHeaderText}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${loginHeaderText.length},"style":"rgba(20,45,99,100)"}],"entityRanges":[],"data":{}}],"entityMap":{}}`
  firstLoginHeaderText.fontSize = 34
  firstLoginHeaderText.margin.marginTop = 10
  firstLoginHeaderText.htmlAttrId = generateCustomHtmlAttrId(
    EntityTypeEnum.Text,
    'login-header',
  )

  const secondLoginHeaderText = createText(
    loginContainer.id,
    container.masterBlockId,
  )
  const loginSubHeaderText = fixedT(
    'entities.member_access.login.default_sub_headline',
  )

  secondLoginHeaderText.rawContentState = `{"blocks":[{"key":"7gjsu","text":"${loginSubHeaderText}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${loginSubHeaderText.length},"style":"rgba(93, 96, 100, 100)"}],"entityRanges":[],"data":{}}],"entityMap":{}}`
  secondLoginHeaderText.fontSize = 18
  secondLoginHeaderText.margin.marginTop = 10

  const emailLoginHeaderText = createText(
    loginContainer.id,
    container.masterBlockId,
  )
  const emailHeaderString = fixedT('entities.member_access.default_email_label')
  emailLoginHeaderText.rawContentState = `{"blocks":[{"key":"7gjsu","text":"${emailHeaderString}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${emailHeaderString.length},"style":"rgba(20, 45, 99, 100)"}],"entityRanges":[],"data":{}}],"entityMap":{}}`
  emailLoginHeaderText.fontSize = 18
  emailLoginHeaderText.margin.marginTop = 10
  emailLoginHeaderText.textAlign = 'left'
  emailLoginHeaderText.htmlAttrId = generateCustomHtmlAttrId(
    EntityTypeEnum.Text,
    'email-header',
  )
  const emailLoginInputPlaceholder = 'entities.field.email.default_placeholder'

  const emailLoginInput = createEntity(
    structureTypes.FORM_INPUT,
    loginContainer.id,
    locale,
    container.masterBlockId,
    {
      marginTop: '0px',
      borderColor: 'rgba(158, 158, 158, 1)',
      borderStyle: 'solid',
      borderWidth: '2px',
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
    },
    {
      inputType: 'text',
      placeholder: `${emailLoginInputPlaceholder}`,
      slug: 'email',
      // @ts-ignore
      appearance: {
        desktop: true,
        mobile: true,
      },
    },
  )
  const passwordLoginHeaderText = createText(
    loginContainer.id,
    container.masterBlockId,
  )
  const passwordHeaderText = fixedT(
    'entities.member_access.default_password_label',
  )
  passwordLoginHeaderText.rawContentState = `{"blocks":[{"key":"7gjsu","text":"${passwordHeaderText}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${passwordHeaderText.length},"style":"rgba(20, 45, 99, 100)"}],"entityRanges":[],"data":{}}],"entityMap":{}}`
  passwordLoginHeaderText.fontSize = 18
  passwordLoginHeaderText.margin.marginTop = 10
  passwordLoginHeaderText.textAlign = 'left'
  passwordLoginHeaderText.htmlAttrId = generateCustomHtmlAttrId(
    EntityTypeEnum.Text,
    'ps-header',
  )
  const passwordLoginInputPlaceholder =
    'entities.member_access.default_password_placeholder'

  const passwordLoginInput = createEntity(
    structureTypes.FORM_INPUT,
    loginContainer.id,
    locale,
    container.masterBlockId,
    {
      marginTop: '0px',
      borderColor: 'rgba(158, 158, 158, 1)',
      borderStyle: 'solid',
      borderWidth: '2px',
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
    },
    {
      inputType: 'text',
      placeholder: `${passwordLoginInputPlaceholder}`,
      slug: 'password',
      // @ts-ignore
      appearance: {
        desktop: true,
        mobile: true,
      },
    },
  )

  const {
    row: explicitLoginRowContainer,
    column1: explicitLoginColumnContainer,
    column2: explicitTextLoginColumnContainer,
  } = createRowColumnLayout({
    parentEntity: loginContainer,
    position,
    columnType: ColumnTypeEnum.COLUMN_6,
  })

  const explicitLoginText = fixedT(
    'entities.member_access.login.default_checkbox',
  )

  const explicitLogin = createEntity(
    EntityTypeEnum.Checkbox,
    explicitLoginColumnContainer.id,
    locale,
    container.masterBlockId,
    { fontSize: '16px' },
    {
      rawContentState: `{"blocks":[{"key":"7gjsu","text":"${explicitLoginText}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`,
    },
  )

  const customLoginForgotPasswordLink = createForgotPassword({
    masterBlockId: container.masterBlockId,
    parentId: explicitTextLoginColumnContainer.id,
    locale,
  })
  const customLoginButtonText =
    'entity_settings.member_access.modes.choices.login'

  const customLoginButton = createEntity(
    structureTypes.BUTTON,
    loginContainer.id,
    locale,
    container.masterBlockId,
    {
      width: '100%',
      backgroundColor: 'rgba(0, 160, 225, 100)',
      borderStyle: 'none',
    },
    {
      text: customLoginButtonText,
      action: buttonActionsTypes.login,
      subText: '',
      redirectionType: '',
      borderType: 'bottomOnly',
      subTextFontSize: '14px',
      subTextColor: 'rgba(255,255,255,0.8)',
    },
  )

  const { column1: confirmRegistrationContainer } = createRowColumnLayout({
    parentEntity: newCustomLogin,
    position,
  })

  const firstConfirmRegistrationHeaderText = createText(
    confirmRegistrationContainer.id,
    container.masterBlockId,
  )
  const confirmRegistrationText = fixedT(
    'entities.member_access.registration.default_headline',
  )
  firstConfirmRegistrationHeaderText.rawContentState = `{"blocks":[{"key":"7gjsu","text":"${confirmRegistrationText}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${confirmRegistrationText.length},"style":"rgba(20, 45, 99, 100)"}],"entityRanges":[],"data":{}}],"entityMap":{}}`
  firstConfirmRegistrationHeaderText.fontSize = 34
  firstConfirmRegistrationHeaderText.margin.marginTop = 10
  firstConfirmRegistrationHeaderText.htmlAttrId = generateCustomHtmlAttrId(
    EntityTypeEnum.Text,
    'conf-r-header',
  )
  const passwordConfirmRegistrationHeaderText = createText(
    confirmRegistrationContainer.id,
    container.masterBlockId,
  )
  passwordConfirmRegistrationHeaderText.rawContentState = `{"blocks":[{"key":"7gjsu","text":"${passwordHeaderText}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${passwordHeaderText.length},"style":"rgba(20, 45, 99, 100)"}],"entityRanges":[],"data":{}}],"entityMap":{}}`
  passwordConfirmRegistrationHeaderText.fontSize = 18
  passwordConfirmRegistrationHeaderText.margin.marginTop = 10
  passwordConfirmRegistrationHeaderText.textAlign = 'left'
  passwordConfirmRegistrationHeaderText.htmlAttrId = generateCustomHtmlAttrId(
    EntityTypeEnum.Text,
    'ps-header',
  )
  const passwordConfirmRegistrationInput = createEntity(
    structureTypes.FORM_INPUT,
    confirmRegistrationContainer.id,
    locale,
    container.masterBlockId,
    {
      marginTop: '0px',
      borderColor: 'rgba(158, 158, 158, 1)',
      borderStyle: 'solid',
      borderWidth: '2px',
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
    },
    {
      inputType: 'text',
      placeholder: `${passwordLoginInputPlaceholder}`,
      slug: 'password',
      // @ts-ignore
      appearance: {
        desktop: true,
        mobile: true,
      },
    },
  )
  const confirmPasswordConfirmRegistrationHeaderText = createText(
    confirmRegistrationContainer.id,
    container.masterBlockId,
  )
  const confirmPasswordString = fixedT(
    'entities.member_access.default_password_confirm_label',
  )
  confirmPasswordConfirmRegistrationHeaderText.rawContentState = `{"blocks":[{"key":"7gjsu","text":"${confirmPasswordString}:","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${confirmPasswordString.length},"style":"rgba(20, 45, 99, 100)"}],"entityRanges":[],"data":{}}],"entityMap":{}}`
  confirmPasswordConfirmRegistrationHeaderText.fontSize = 18
  confirmPasswordConfirmRegistrationHeaderText.margin.marginTop = 10
  confirmPasswordConfirmRegistrationHeaderText.textAlign = 'left'
  firstConfirmRegistrationHeaderText.htmlAttrId = generateCustomHtmlAttrId(
    EntityTypeEnum.Text,
    'conf-ps-header',
  )
  const confirmPasswordConfirmRegistrationInput = createEntity(
    structureTypes.FORM_INPUT,
    confirmRegistrationContainer.id,
    locale,
    container.masterBlockId,
    {
      marginTop: '0px',
      borderColor: 'rgba(158, 158, 158, 1)',
      borderStyle: 'solid',
      borderWidth: '2px',
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
    },
    {
      inputType: 'text',
      placeholder: `${passwordLoginInputPlaceholder}`,
      slug: 'confirm_password',
      // @ts-ignore
      appearance: {
        desktop: true,
        mobile: true,
      },
    },
  )
  const customConfirmRegistrationButtonText =
    'components.core.text_editor.link_menu.confirm'

  const customConfirmRegistrationButton = createEntity(
    structureTypes.BUTTON,
    confirmRegistrationContainer.id,
    locale,
    container.masterBlockId,
    {
      width: '100%',
      backgroundColor: 'rgba(0, 160, 225, 100)',
      borderStyle: 'none',
    },
    {
      subText: '',
      action: buttonActionsTypes.confirmRegistration,
      text: customConfirmRegistrationButtonText,
      redirectionType: '',
      borderType: 'bottomOnly',
      subTextFontSize: '14px',
      subTextColor: 'rgba(255,255,255,0.8)',
    },
  )

  const resetPasswordContainer = createColumn({ parentId: newCustomLogin.id })
  const firstResetPasswrodHeaderText = createText(
    resetPasswordContainer.id,
    container.masterBlockId,
  )
  const resetPasswordString = fixedT(
    'entities.member_access.request.default_headline',
  )
  firstResetPasswrodHeaderText.rawContentState = `{"blocks":[{"key":"7gjsu","text":"${resetPasswordString}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${resetPasswordString.length},"style":"rgba(20, 45, 99, 100)"}],"entityRanges":[],"data":{}}],"entityMap":{}}`
  firstResetPasswrodHeaderText.fontSize = 34
  firstResetPasswrodHeaderText.margin.marginTop = 10
  firstResetPasswrodHeaderText.htmlAttrId = generateCustomHtmlAttrId(
    EntityTypeEnum.Text,
    'reset-ps-header',
  )
  const emailResetPasswrodHeaderText = createText(
    resetPasswordContainer.id,
    container.masterBlockId,
  )
  emailResetPasswrodHeaderText.rawContentState = `{"blocks":[{"key":"7gjsu","text":"${emailHeaderString}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${emailHeaderString.length},"style":"rgba(20, 45, 99, 100)"}],"entityRanges":[],"data":{}}],"entityMap":{}}`
  emailResetPasswrodHeaderText.fontSize = 18
  emailResetPasswrodHeaderText.margin.marginTop = 10
  emailResetPasswrodHeaderText.textAlign = 'left'
  emailResetPasswrodHeaderText.htmlAttrId = generateCustomHtmlAttrId(
    EntityTypeEnum.Text,
    'email-header',
  )
  const emailResetPasswrodInput = createEntity(
    structureTypes.FORM_INPUT,
    resetPasswordContainer.id,
    locale,
    container.masterBlockId,
    {
      marginTop: '0px',
      borderColor: 'rgba(158, 158, 158, 1)',
      borderStyle: 'solid',
      borderWidth: '2px',
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
    },
    {
      inputType: 'text',
      placeholder: `${emailLoginInputPlaceholder}`,
      slug: 'email',
      // @ts-ignore
      appearance: {
        desktop: true,
        mobile: true,
      },
    },
  )
  const customResetPasswrodButtonText =
    'entities.member_access.request.default_button_text'

  const customResetPasswrodButton = createEntity(
    structureTypes.BUTTON,
    resetPasswordContainer.id,
    locale,
    container.masterBlockId,
    {
      width: '100%',
      backgroundColor: 'rgba(0, 160, 225, 100)',
      borderStyle: 'none',
    },
    {
      subText: '',
      action: buttonActionsTypes.resetPassword,
      text: customResetPasswrodButtonText,
      redirectionType: '',
      borderType: 'bottomOnly',
      subTextFontSize: '14px',
      subTextColor: 'rgba(255,255,255,0.8)',
    },
  )

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        newCustomLogin.id,
        position,
      ),
    },
    [newCustomLogin.id]: {
      ...newCustomLogin,
      parentId: descendantEntity.id,
      childIds: [
        loginContainer.id,
        confirmRegistrationContainer.id,
        resetPasswordContainer.id,
      ],
    },
    [loginContainer.id]: {
      ...loginContainer,
      parentId: newCustomLogin.id,
      childIds: [
        firstLoginHeaderText.id,
        secondLoginHeaderText.id,
        emailLoginHeaderText.id,
        emailLoginInput.id,
        passwordLoginHeaderText.id,
        passwordLoginInput.id,
        explicitLoginRowContainer.id,
        customLoginButton.id,
      ],
    },
    [firstLoginHeaderText.id]: firstLoginHeaderText,
    [secondLoginHeaderText.id]: secondLoginHeaderText,
    [emailLoginHeaderText.id]: {
      ...emailLoginHeaderText,
    },
    [emailLoginInput.id]: emailLoginInput,
    [passwordLoginHeaderText.id]: passwordLoginHeaderText,
    [passwordLoginInput.id]: passwordLoginInput,
    [explicitLoginRowContainer.id]: {
      ...explicitLoginRowContainer,
      childIds: [
        explicitLoginColumnContainer.id,
        explicitTextLoginColumnContainer.id,
      ],
    },
    [explicitLoginColumnContainer.id]: {
      ...explicitLoginColumnContainer,
      childIds: [explicitLogin.id],
    },
    [explicitLogin.id]: explicitLogin,
    [explicitTextLoginColumnContainer.id]: {
      ...explicitTextLoginColumnContainer,
      childIds: [customLoginForgotPasswordLink.id],
    },
    [customLoginForgotPasswordLink.id]: customLoginForgotPasswordLink,
    [customLoginButton.id]: customLoginButton,
    [confirmRegistrationContainer.id]: {
      ...confirmRegistrationContainer,
      childIds: [
        firstConfirmRegistrationHeaderText.id,
        passwordConfirmRegistrationHeaderText.id,
        passwordConfirmRegistrationInput.id,
        confirmPasswordConfirmRegistrationHeaderText.id,
        confirmPasswordConfirmRegistrationInput.id,
        customConfirmRegistrationButton.id,
      ],
    },
    [firstConfirmRegistrationHeaderText.id]: firstConfirmRegistrationHeaderText,
    [passwordConfirmRegistrationHeaderText.id]:
      passwordConfirmRegistrationHeaderText,
    [passwordConfirmRegistrationInput.id]: passwordConfirmRegistrationInput,
    [confirmPasswordConfirmRegistrationHeaderText.id]:
      confirmPasswordConfirmRegistrationHeaderText,
    [confirmPasswordConfirmRegistrationInput.id]:
      confirmPasswordConfirmRegistrationInput,
    [customConfirmRegistrationButton.id]: customConfirmRegistrationButton,
    [resetPasswordContainer.id]: {
      ...resetPasswordContainer,
      parentId: newCustomLogin.id,
      childIds: [
        firstResetPasswrodHeaderText.id,
        emailResetPasswrodHeaderText.id,
        emailResetPasswrodInput.id,
        customResetPasswrodButton.id,
      ],
    },
    [firstResetPasswrodHeaderText.id]: {
      ...firstResetPasswrodHeaderText,
      parentId: resetPasswordContainer.id,
    },
    [emailResetPasswrodHeaderText.id]: emailResetPasswrodHeaderText,
    [emailResetPasswrodInput.id]: emailResetPasswrodInput,
    [customResetPasswrodButton.id]: customResetPasswrodButton,
  }
}

export function createBlogContentPlaceholderFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const blogContentPlaceholder = createEntity(
    structureTypes.BLOG_CONTENT_PLACEHOLDER,
    descendantEntity.id,
    locale,
    container.masterBlockId,
  )

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        blogContentPlaceholder.id,
        position,
      ),
    },
    [blogContentPlaceholder.id]: {
      ...blogContentPlaceholder,
      parentId: descendantEntity.id,
    },
  }
}

export function createBlogPostListFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
  categoryId?: number,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const blogPostList = createBlogPostListing(
    container.id,
    container.masterBlockId,
  )

  if (categoryId) {
    blogPostList.category = `${categoryId}`
  }

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        blogPostList.id,
        position,
      ),
    },
    [blogPostList.id]: {
      ...blogPostList,
      parentId: descendantEntity.id,
    },
  }
}

export function createBlogPostImageFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )

  const blogPostImage = createBlogPostImage(
    descendantEntity.id,
    descendantEntity.masterBlockId,
  )

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        blogPostImage.id,
        position,
      ),
    },
    [blogPostImage.id]: blogPostImage,
  }
}

export function createContentTableFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )

  const fixedT = i18n.getFixedT(locale)

  const blogPostContentTable = createBlogPostContentTable(
    descendantEntity.id,
    descendantEntity.masterBlockId,
  )
  blogPostContentTable.headerTitle = fixedT(
    'entities.content_table.default_header_title',
  )

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        blogPostContentTable.id,
        position,
      ),
    },
    [blogPostContentTable.id]: blogPostContentTable,
  }
}

export function createContactUsFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const fixedT = i18n.getFixedT(locale)

  const contactUs = createContactUs(
    descendantEntity.id,
    descendantEntity.masterBlockId,
  )
  const textArea = createTextArea(contactUs.id, contactUs.masterBlockId)
  textArea.placeholder = fixedT('entities.textarea.contact_us_placeholder')

  const { row, column1, column2 } = createRowColumnLayout({
    parentEntity: contactUs,
    position,
    columnType: ColumnTypeEnum.COLUMN_6,
  })

  row.padding = {
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  }
  row.mobilePadding = {
    paddingBottom: 5,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 5,
  }

  const firstNameField = createContactUsField(
    column1.id,
    column1.masterBlockId,
    FieldSlugEnum.FirstName,
  )
  firstNameField.placeholder = fixedT(
    'entities.field.contact_us_first_name_placeholder',
  )

  const subjectField = createContactUsField(
    contactUs.id,
    contactUs.masterBlockId,
    FieldSlugEnum.Subject,
  )
  subjectField.placeholder = fixedT('entities.subject.contact_us_placeholder')

  const emailField = createContactUsField(
    column2.id,
    column2.masterBlockId,
    FieldSlugEnum.Email,
  )
  emailField.placeholder = fixedT('entities.field.contact_us_email_placeholder')
  const recaptcha = createRecaptcha(
    EntityTypeEnum.ContactUsRecaptcha,
    contactUs.id,
    contactUs.masterBlockId,
  )

  const attachments = createAttachments(contactUs.id, contactUs.masterBlockId)
  const contactUsButton = createContactUsButton(
    contactUs.id,
    fixedT('entities.button.contact_us_default_text'),
    contactUs.masterBlockId,
  )

  row.childIds = [column1.id, column2.id]
  contactUs.childIds = [
    row.id,
    subjectField.id,
    textArea.id,
    attachments.id,
    recaptcha.id,
    contactUsButton.id,
  ]
  column1.childIds = [firstNameField.id]
  column2.childIds = [emailField.id]

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(descendantEntity.childIds, contactUs.id, position),
    },
    [contactUs.id]: contactUs,
    [row.id]: row,
    [column1.id]: column1,
    [column2.id]: column2,
    [subjectField.id]: subjectField,
    [textArea.id]: textArea,
    [emailField.id]: emailField,
    [firstNameField.id]: firstNameField,
    [attachments.id]: attachments,
    [recaptcha.id]: recaptcha,
    [contactUsButton.id]: contactUsButton,
  }
}

export function createBreadcrumbsFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )

  const breadcrumbs = createBreadcrumbs(
    descendantEntity.id,
    descendantEntity.masterBlockId,
  )

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        breadcrumbs.id,
        position,
      ),
    },
    [breadcrumbs.id]: breadcrumbs,
  }
}

export function createMenuFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const fixedT = i18n.getFixedT(locale)

  const menu = createMenu(descendantEntity.id, descendantEntity.masterBlockId)

  menu.menuItems[0].text = fixedT('entity_settings.menu.item.home_placeholder')
  menu.menuItems[1].text = fixedT(
    'entity_settings.menu.item.contacts_placeholder',
  )

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(descendantEntity.childIds, menu.id, position),
    },
    [menu.id]: menu,
  }
}

export function createSurveyFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )

  const survey = createSurvey(
    descendantEntity.id,
    descendantEntity.masterBlockId,
  )

  const {
    row,
    column1,
    structure: surveyStructure,
  } = createRowColumnLayout({
    parentEntity: survey,
    position,
  })

  row.padding = {
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  }
  row.mobilePadding = {
    paddingBottom: 5,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 5,
  }

  const emailPlaceholder = 'entities.field.email.default_placeholder'
  const firstNamePlaceholder = 'entities.field.first_name.default_placeholder'
  const formStyles = defaultStyles[structureTypes.FORM_INPUT]

  const fieldEmail = createEntity(
    structureTypes.FORM_INPUT,
    column1.id,
    locale,
    column1.masterBlockId,
    // @ts-ignore
    formStyles,
    {
      inputType: 'text',
      placeholder: emailPlaceholder,
      slug: FieldSlugEnum.Email,
      // @ts-ignore
      appearance: {
        desktop: true,
        mobile: true,
      },
    },
  )
  const fieldFirstName = createEntity(
    structureTypes.FORM_INPUT,
    column1.id,
    locale,
    column1.masterBlockId,
    // @ts-ignore
    formStyles,
    {
      inputType: 'text',
      placeholder: firstNamePlaceholder,
      slug: FieldSlugEnum.FirstName,
      // @ts-ignore
      appearance: {
        desktop: true,
        mobile: true,
      },
    },
  )

  const button = createEntity(
    structureTypes.BUTTON,
    column1.id,
    locale,
    column1.masterBlockId,
    {},
    {
      action: buttonActionsTypes.sendForm,
      redirectionType: redirectionTypes.none,
    },
  )
  column1.childIds = [fieldEmail.id, fieldFirstName.id, button.id]

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(descendantEntity.childIds, survey.id, position),
    },
    ...surveyStructure,
    [fieldEmail.id]: fieldEmail,
    [fieldFirstName.id]: fieldFirstName,
    [button.id]: button,
  }
}

export function createNewBlogContentPlaceholderFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
  masterBlockId?: string,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const blogContentPlaceholder = createBlogContentPlaceholder(
    container.id,
    masterBlockId,
  )
  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        blogContentPlaceholder.id,
        position,
      ),
    },
    [blogContentPlaceholder.id]: {
      ...blogContentPlaceholder,
      parentId: descendantEntity.id,
    },
  }
}

export function createVideoFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )

  const video = createVideo(descendantEntity.id, descendantEntity.masterBlockId)

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(descendantEntity.childIds, video.id, position),
    },
    [video.id]: video,
  }
}

export function createAudioFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )

  const audio = createAudio(descendantEntity.id, descendantEntity.masterBlockId)

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(descendantEntity.childIds, audio.id, position),
    },
    [audio.id]: audio,
  }
}

export function createWebinarSessionVideoFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const webinarSessionVideo = createWebinarSessionVideo(
    locale,
    descendantEntity.id,
    descendantEntity.masterBlockId,
  )
  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        webinarSessionVideo.id,
        position,
      ),
    },
    [webinarSessionVideo.id]: webinarSessionVideo,
  }
}

export function createWebinarCallToActionFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )

  const fixedT = i18n.getFixedT(locale)

  const webinarCallToAction = createWebinarCallToAction(
    descendantEntity.id,
    descendantEntity.masterBlockId,
  )

  webinarCallToAction.text = fixedT(
    'entities.webinar_session_call_to_action.default_text',
  )

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        webinarCallToAction.id,
        position,
      ),
    },
    [webinarCallToAction.id]: {
      ...webinarCallToAction,
      parentId: descendantEntity.id,
    },
  }
}

export function createBlogCategoryTitleFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )

  const blogCategoryTitle = createBlogCategoryTitle(
    descendantEntity.id,
    descendantEntity.masterBlockId,
  )

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        blogCategoryTitle.id,
        position,
      ),
    },
    [blogCategoryTitle.id]: blogCategoryTitle,
  }
}

export function createBlogCategoryDescriptionFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )

  const blogCategoryDescription = createBlogCategoryDescription(
    descendantEntity.id,
    descendantEntity.masterBlockId,
  )

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        blogCategoryDescription.id,
        position,
      ),
    },
    [blogCategoryDescription.id]: blogCategoryDescription,
  }
}

export function createDefaultEntityStructure<T extends EntityInterface>(
  createEntity: (parentId: string, masterBlockId?: string) => T,
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )

  const newEntity = createEntity(
    descendantEntity.id,
    descendantEntity.masterBlockId,
  )

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(descendantEntity.childIds, newEntity.id, position),
    },
    [newEntity.id]: newEntity,
  }
}

function createSectionLayout12(
  rootEntity: OldEntityWithChildIds,
  position: number,
  columnType?: ColumnTypeEnum,
) {
  const section = createSection(rootEntity.id, rootEntity.masterBlockId)
  const layout = createRowColumnLayout({
    parentEntity: section,
    position,
    columnType,
  })

  return {
    column: layout.column1,
    structure: {
      [rootEntity.id]: {
        ...rootEntity,
        childIds: insertChild(rootEntity.childIds, section.id, position),
      },
      ...layout.structure,
    },
  }
}

export function createRowLayout12(
  parentEntity: OldEntityWithChildIds | EntityWithChildIdsInterface,
  locale: string,
  position: number,
) {
  const row = createEntity(
    structureTypes.ROW,
    parentEntity.id,
    locale,
    parentEntity.masterBlockId,
  ) as OldEntityWithChildIds
  const column = createEntity(
    structureTypes.COLUMN_12,
    row.id,
    locale,
    row.masterBlockId,
  ) as OldEntityWithChildIds

  return {
    column,
    structure: {
      [parentEntity.id]: {
        ...parentEntity,
        childIds: insertChild(parentEntity.childIds, row.id, position),
      },
      [row.id]: { ...row, childIds: [column.id] },
      [column.id]: column,
    },
  }
}

export function createHorizontalLineLibrary(
  container: OldEntityWithChildIds | EntityInnerItemInterface,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const horizontalLine = createHorizontalLine(
    container.id,
    container.masterBlockId,
  )

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        horizontalLine.id,
        position,
      ),
    },
    [horizontalLine.id]: {
      ...horizontalLine,
      parentId: descendantEntity.id,
    },
  }
}

export function createFacebookCommentsLibrary(
  container: OldEntityWithChildIds | EntityInnerItemInterface,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const facebookComments = createFacebookComments(
    container.id,
    container.masterBlockId,
  )

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        facebookComments.id,
        position,
      ),
    },
    [facebookComments.id]: {
      ...facebookComments,
      parentId: descendantEntity.id,
    },
  }
}

export function createCountdownLibrary(
  container: OldEntityWithChildIds | EntityInnerItemInterface,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const countdown = createCountdown(container.id, container.masterBlockId)

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(descendantEntity.childIds, countdown.id, position),
    },
    [countdown.id]: {
      ...countdown,
      parentId: descendantEntity.id,
    },
  }
}

export function createLanguageSwitcherLibrary(
  container: OldEntityWithChildIds | EntityInnerItemInterface,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const languageSwitcher = createLanguageSwitcher(
    container.id,
    container.masterBlockId,
  )

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        languageSwitcher.id,
        position,
      ),
    },
    [languageSwitcher.id]: {
      ...languageSwitcher,
      parentId: descendantEntity.id,
    },
  }
}

export function createRawHtmlLibrary(
  container: OldEntityWithChildIds | EntityInnerItemInterface,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const rawHtml = createRawHtml(container.id, container.masterBlockId)

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(descendantEntity.childIds, rawHtml.id, position),
    },
    [rawHtml.id]: {
      ...rawHtml,
      parentId: descendantEntity.id,
    },
  }
}

export function createOptInRecaptchaFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const recaptcha = createRecaptcha(
    EntityTypeEnum.OptInRecaptcha,
    container.id,
    container.masterBlockId,
  )

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(descendantEntity.childIds, recaptcha.id, position),
    },
    [recaptcha.id]: {
      ...recaptcha,
      parentId: descendantEntity.id,
    },
  }
}

export function createTwitterTweetButtonLibrary(
  container: OldEntityWithChildIds | EntityInnerItemInterface,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const twitterTweetButton = createTwitterTweetButton(
    container.id,
    container.masterBlockId,
  )

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        twitterTweetButton.id,
        position,
      ),
    },
    [twitterTweetButton.id]: {
      ...twitterTweetButton,
      parentId: descendantEntity.id,
    },
  }
}

export function createCustomerTypeFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const customerTypeEntity = createCustomerType(
    container.id,
    container.masterBlockId,
  )

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        customerTypeEntity.id,
        position,
      ),
    },
    [customerTypeEntity.id]: {
      ...customerTypeEntity,
      parentId: descendantEntity.id,
    },
  }
}

export function createPaymentMethodFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const customerTypeEntity = createPaymentMethod(
    container.id,
    container.masterBlockId,
  )

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        customerTypeEntity.id,
        position,
      ),
    },
    [customerTypeEntity.id]: {
      ...customerTypeEntity,
      parentId: descendantEntity.id,
    },
  }
}

export function createSectionFromLibrary(
  body: OldEntityWithChildIds | EntityWithChildIdsInterface,
  position = 0,
): EntitiesStructure {
  const section = createSection(body.id, body.masterBlockId)

  const { structure } = createRowColumnLayout({
    parentEntity: section,
    position,
  })

  return {
    [body.id]: {
      ...body,
      childIds: insertChild(body.childIds, section.id, position),
    },
    ...structure,
  }
}

export function createRowFromLibrary(
  container: OldEntityWithChildIds | EntityWithChildIdsInterface,
  locale: string,
  position = 0,
  columnType?: ColumnTypeEnum,
): EntitiesStructure {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
    columnType,
  )
  if (container.type === descendantEntity.type) {
    const { structure: layoutStructure } = createRowColumnLayout({
      parentEntity: descendantEntity as EntityWithChildIdsInterface,
      position,
      columnType,
    })
    return {
      ...structure,
      ...layoutStructure,
    }
  } else {
    return {
      ...structure,
      [descendantEntity.id]: descendantEntity,
    }
  }
}

export function createContentBoxFromLibrary(
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
) {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )
  const contentBoxEntity = createContentBox(
    container.id,
    container.masterBlockId,
  )
  const fixedT = i18n.getFixedT(locale)
  contentBoxEntity.header.title = fixedT(
    'entities.content_box.default_header_title',
  )

  const { structure: layoutStructure } = createRowColumnLayout({
    parentEntity: contentBoxEntity,
    position,
  })

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        contentBoxEntity.id,
        position,
      ),
    },
    [contentBoxEntity.id]: {
      ...contentBoxEntity,
      parentId: descendantEntity.id,
    },
    ...layoutStructure,
  }
}

export const createCheckboxFromLibrary = (
  container: OldEntityWithChildIds | EntityWithChildIdsInterface,
  locale: string,
  position = 0,
) => {
  const fixedT = i18n.getFixedT(locale)

  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )

  const checkboxEntity = createCheckbox(
    container.id,
    fixedT('entities.explicit_consent.default_text'),
    container.masterBlockId,
  )
  checkboxEntity.errorText = fixedT('settings_options.checkbox_message')

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        checkboxEntity.id,
        position,
      ),
    },
    [checkboxEntity.id]: {
      ...checkboxEntity,
      parentId: descendantEntity.id,
    },
  }
}

export const createBulletListFromLibrary = (
  container: OldEntityWithChildIds | EntityWithChildIdsInterface,
  locale: string,
  position = 0,
) => {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )

  const bulletListEntity = createBulletList(
    container.id,
    container.masterBlockId,
  )

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        bulletListEntity.id,
        position,
      ),
    },
    [bulletListEntity.id]: {
      ...bulletListEntity,
      parentId: descendantEntity.id,
    },
  }
}

export const createShippingFeesFromLibrary = (
  container: OldEntityWithChildIds | EntityWithChildIdsInterface,
  locale: string,
  position = 0,
) => {
  const { structure, descendantEntity } = createContainerStructure(
    container,
    locale,
    position,
  )

  const shippingEntity = createShippingFees(container.id, container.masterBlockId)

  return {
    ...structure,
    [descendantEntity.id]: {
      ...descendantEntity,
      childIds: insertChild(
        descendantEntity.childIds,
        shippingEntity.id,
        position,
      ),
    },
    [shippingEntity.id]: {
      ...shippingEntity,
      parentId: descendantEntity.id,
    },
  }
}

export function createContainerStructure(
  containerEntity:
    | OldEntityWithChildIds
    | EntityInnerItemInterface
    | EntityWithChildIdsInterface
    | BaseEntityWithChildIdsInterface,
  locale: string,
  position: number,
  columnType?: ColumnTypeEnum,
) {
  let structure: EntitiesStructure
  let descendantEntity:
    | OldEntityWithChildIds
    | EntityInnerItemInterface
    | EntityWithChildIdsInterface
    | BaseEntityWithChildIdsInterface
  switch (containerEntity.type) {
    case structureTypes.BODY:
    case structureTypes.BLOG_PAGE_BODY:
    case structureTypes.BLOG_POST_BODY:
    case structureTypes.BLOG_POST_LAYOUT_BODY: {
      const layout = createSectionLayout12(
        containerEntity as OldEntityWithChildIds,
        position,
        columnType,
      )
      descendantEntity = layout.column
      structure = layout.structure
      break
    }

    case EntityTypeEnum.Section:
    case EntityTypeEnum.Row:
    case EntityTypeEnum.Survey:
    case structureTypes.SURVEY:
    case structureTypes.INLINE:
    case structureTypes.REMOTE_POPUP:
    case structureTypes.POPUP:
    case structureTypes.SECTION: {
      const layout = createRowColumnLayout({
        parentEntity: containerEntity as EntityWithChildIdsInterface,
        position,
        columnType,
      })
      descendantEntity = layout.column1
      structure = layout.structure
      break
    }
    case structureTypes.ROW: {
      const layout = createRowLayout12(
        containerEntity as OldEntityWithChildIds,
        locale,
        position,
      )
      descendantEntity = layout.column
      structure = layout.structure
      break
    }
    case EntityTypeEnum.FaqItem:
    case EntityTypeEnum.CarouselItem:
    case structureTypes.CONTENT_BOX:
    case EntityTypeEnum.ContentBox:
    case structureTypes.COLUMN:
    case EntityTypeEnum.Column:
    case structureTypes.ORDER_BUMP:
    case structureTypes.TWO_STEP_PAYMENT_FORM:
    case structureTypes.TWO_STEP_PAYMENT_FORM_STEP_OPT_IN:
    case structureTypes.TWO_STEP_PAYMENT_FORM_STEP_PAYMENT:
    case EntityTypeEnum.ContactUs: {
      descendantEntity = containerEntity
      structure = { [descendantEntity.id]: descendantEntity }
      break
    }
    default:
      throw new Error(`container entity unknown ${containerEntity.type}`)
  }

  return { structure, descendantEntity }
}

export const generateHtmlAttrId = (
  entityType: EntityTypeEnum | keyof typeof structureTypes,
) => `${entityType.toLowerCase().replace('_', '-')}-${uuid().slice(0, 8)}`

export const renewEntityHtmlAttrId = (
  entity: EntityInterface | OldEntityInterface,
) => {
  if (entity.type in EntityTypeEnum) {
    return {
      ...entity,
      htmlAttrId: generateHtmlAttrId(entity.type),
    }
  } else {
    const updatedEntity = entity as OldEntityInterface
    return {
      ...updatedEntity,
      options: {
        ...updatedEntity.options,
        attrId: generateHtmlAttrId(updatedEntity.type),
      },
    }
  }
}

export const generateCustomHtmlAttrId = (
  entityType: EntityTypeEnum,
  customString: string,
) =>
  `${entityType.toLowerCase().replace('_', '-')}-${customString}${uuid().slice(
    0,
    8,
  )}`

export const insertChild = (
  childIds: string[],
  id: string,
  position: number,
) => [...childIds.slice(0, position), id, ...childIds.slice(position)]

export function createEntity(
  type: EntityTypeEnum | string,
  parentId: string,
  locale: string,
  masterBlockId?: string,
  styles?: Record<string, string>,
  options?: Record<string, string>,
) {
  let entity: EntityInterface | any
  switch (type) {
    case EntityTypeEnum.Carousel:
      entity = createCarousel(parentId, masterBlockId)
      break
    default:
      entity = createStructureOld.createEntity(
        locale,
        type,
        parentId,
        styles,
        {},
        options,
        {},
        // @ts-ignore
        masterBlockId,
      )
  }

  return entity
}
