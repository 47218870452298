import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  getCreditCardPaymentMethodTitle,
  creditCardMethodTypes,
} from 'common/components/entities/PaymentMethods/utils/creditCardsUtils'
import {
  paymentMethodsNames,
  paymentMethodsImages,
  paymentMethodsImagesSize,
} from 'common/constants/paymentMethodTypes'
import { PaymentMethodLabelUi } from './index'
import InputRadioUi from './ui/InputRadioUi'
import PaymentMethodSwitcherCreditCardUi from './ui/PaymentMethodSwitcherCreditCardUi'
import PaymentMethodSwitcherItemImageUi from './ui/PaymentMethodSwitcherItemImageUi'
import PaymentMethodSwitcherItemUi from './ui/PaymentMethodSwitcherItemUi'

function PaymentMethodSwitcher({
  paymentMethods,
  activeMethod,
  changeMethod,
  locale,
  isMobile,
  fontSize,
  mobileFontSize,
  lineHeight,
  mobileLineHeight,
  fontWeight,
  fontStyle,
  fontFamily,
  mobileFontStyle,
  mobileFontWeight,
  mobileFontFamily,
  color,
  mobileColor,
}) {
  const { i18n } = useTranslation('common')
  const fixedT = i18n.getFixedT(locale, 'common')

  return (
    <React.Fragment>
      {paymentMethods
        .filter(method => creditCardMethodTypes.includes(method))
        .map(method => (
          <React.Fragment key={method}>
            <PaymentMethodSwitcherItemUi
              onClick={() => changeMethod(method)}
              active={activeMethod === method}
              data-test-id={`payment-method-${method}`}
            >
              <InputRadioUi checked={activeMethod === method} />
              <PaymentMethodLabelUi
                fontSize={fontSize}
                mobileFontSize={mobileFontSize}
                lineHeight={lineHeight}
                mobileLineHeight={mobileLineHeight}
                fontFamily={fontFamily}
                fontWeight={fontWeight}
                fontStyle={fontStyle}
                mobileFontStyle={mobileFontStyle}
                mobileFontWeight={mobileFontWeight}
                mobileFontFamily={mobileFontFamily}
                color={color}
                mobileColor={mobileColor}
              >
                {getCreditCardPaymentMethodTitle(method, fixedT)}
              </PaymentMethodLabelUi>
              <PaymentMethodSwitcherCreditCardUi />
            </PaymentMethodSwitcherItemUi>
          </React.Fragment>
        ))}
      {paymentMethods
        .filter(pm => creditCardMethodTypes.includes(pm) === false)
        .map(method => (
          <React.Fragment key={method}>
            <PaymentMethodSwitcherItemUi
              key={'method' + method}
              onClick={() => changeMethod(method)}
              active={activeMethod === method}
              data-test-id={`payment-method-${method}`}
            >
              <InputRadioUi checked={activeMethod === method} />
              <PaymentMethodLabelUi
                fontSize={fontSize}
                mobileFontSize={mobileFontSize}
                lineHeight={lineHeight}
                mobileLineHeight={mobileLineHeight}
                fontFamily={fontFamily}
                fontWeight={fontWeight}
                fontStyle={fontStyle}
                mobileFontStyle={mobileFontStyle}
                mobileFontWeight={mobileFontWeight}
                mobileFontFamily={mobileFontFamily}
                color={color}
                mobileColor={mobileColor}
              >
                {paymentMethodsNames[method]}
              </PaymentMethodLabelUi>
              {paymentMethodsImages[method] && (
                <PaymentMethodSwitcherItemImageUi
                  src={paymentMethodsImages[method]}
                  width={paymentMethodsImagesSize[method].width}
                  height={paymentMethodsImagesSize[method].height}
                />
              )}
            </PaymentMethodSwitcherItemUi>
          </React.Fragment>
        ))}
    </React.Fragment>
  )
}

PaymentMethodSwitcher.propTypes = {
  paymentMethods: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeMethod: PropTypes.bool.isRequired,
  changeMethod: PropTypes.func.isRequired,
  locale: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  fontSize: PropTypes.number,
  mobileFontSize: PropTypes.number,
  lineHeight: PropTypes.string,
  mobileLineHeight: PropTypes.string,
  fontFamily: PropTypes.string,
  fontWeight: PropTypes.string,
  fontStyle: PropTypes.string,
  mobileFontFamily: PropTypes.string,
  mobileFontWeight: PropTypes.string,
  mobileFontStyle: PropTypes.string,
  color: PropTypes.string,
  mobileColor: PropTypes.string,
}

export default PaymentMethodSwitcher
