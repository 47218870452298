import EntityInterface, {
  BaseEntityInterface,
  EntityInnerItemInterface,
} from 'common/types/entities/EntityInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { GoogleFontProperties } from 'common/utils/fontsUtils'
import { PagesInterface } from 'client/db/tables/pages'

export const UPDATE_ENTITY = 'UPDATE_ENTITY'
export const ADD_STRUCTURE = 'ADD_STRUCTURE'
export const REMOVE_ENTITY = 'REMOVE_ENTITY'
export const REMOVE_ENTITY_BY_ID_AND_PARENT_ID =
  'REMOVE_ENTITY_BY_ID_AND_PARENT_ID'
export const UPDATE_GLOBAL_SETTINGS = 'UPDATE_PAGE_SETTINGS'
export const TOGGLE_INSTANT_UPLOAD = 'TOGGLE_INSTANT_UPLOAD'
export const TOGGLE_SETTINGS = 'TOGGLE_SETTINGS'
export const UPDATE_PAGE_FROM_DB = 'UPDATE_PAGE_FROM_DB'
export interface UpdateEntityAction<T> {
  type: typeof UPDATE_ENTITY
  payload: T
}

export interface UpdatePageFromDbAction {
  type: typeof UPDATE_PAGE_FROM_DB
  payload: PagesInterface['page']
}

export interface AddStructureAction {
  type: typeof ADD_STRUCTURE
  payload: Record<
    string,
    BaseEntityInterface | OldEntityInterface | EntityInnerItemInterface
  >
}

export interface RemoveEntityAction<T> {
  type: typeof REMOVE_ENTITY
  payload: T
}

export interface RemoveEntityByIdAndPArentIdAction {
  type: typeof REMOVE_ENTITY_BY_ID_AND_PARENT_ID
  payload: {
    parentId: string
    id: string
  }
}

export interface UpdateGlobalSettings {
  type: typeof UPDATE_GLOBAL_SETTINGS
  payload: GoogleFontProperties
}

export interface ToggleInstantUpload {
  type: typeof TOGGLE_INSTANT_UPLOAD
  payload: boolean
}

export interface ToggleSettings {
  type: typeof TOGGLE_SETTINGS
  payload: { id: string }
}
