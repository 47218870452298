import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {
  imageActionsKeys,
  imageActionsNames,
  widthKeys,
  widthValues,
} from 'common/constants/settings'
import structureTypes from 'common/constants/structureTypes'
import * as types from 'common/types'
import { disableInstantUpload } from 'client/actions/actionsManagement'
import { mimeTypes } from 'client/constants/editorSettings'
import { isInstantUploadEnabled } from 'client/reducers/managementReducer'
import { getByType, getEntityAncestorByType } from 'client/reducers/pageReducer'
import EntityTypeEnum from '../../../../../../../common/enums/entityTypeEnum'
import { FileUpload, Range, Input, Checkbox, Select } from '../components'
import { DelayOption, DeviceAppearance, Attributes } from '../options'
import ImageSize from '../options/ImageSize'
import GroupTitle from '../styleComponents/GroupTitle'
import {
  MarginsStyle,
  ShadowStyle,
  FlexAlignStyle,
  CornerStyle,
} from '../styles'

class ImageSettings extends PureComponent {
  constructor(props) {
    super(props)
    if (props.section) {
      const widthName = props.section.options.width || widthKeys.medium
      this.maxSize =
        widthName === widthKeys.fullPage
          ? this.getMaxSizeByImage()
          : widthValues[widthName]
    } else {
      this.maxSize = this.getMaxSizeByImage()
    }
  }

  getMaxSizeByImage = () => {
    const imageWrapper = document.getElementById(this.props.entity.id)
    return imageWrapper ? imageWrapper.getBoundingClientRect().width : 0
  }

  getImageRatio = () => {
    const imageWrapper = document.getElementById(this.props.entity.id)
    if (!imageWrapper) {
      return
    }

    const { width, height } = imageWrapper.getBoundingClientRect()
    return (width / height).toFixed(2)
  }

  updateImage = file => {
    this.props.updateOption('srcFileId')(file.id)
    const self = this
    // eslint-disable-next-line no-undef
    const img = new Image()
    img.src = file.path
    // eslint-disable-next-line func-names
    img.onload = function () {
      // eslint-disable-next-line no-undef
      const imageWrapper = document.getElementById(self.props.entity.id)
      const maxWidth = imageWrapper.getBoundingClientRect().width
      let resultWidth = maxWidth

      if (maxWidth > this.width) {
        resultWidth = this.width
      }

      self.props.updateStyle('width')(`${resultWidth}px`)
      self.props.updateOption('ratio')((this.width / this.height).toFixed(2))
    }
  }

  updateAction = action => {
    const { entity } = this.props
    const popup =
      action !== imageActionsKeys.showPopup ? '' : entity.options.popup

    const updatedEntity = {
      ...entity,
      options: {
        ...entity.options,
        action,
        popup,
      },
    }

    this.props.update(updatedEntity)
  }

  updatePopup = popupId => {
    this.props.updateOption('popup')(popupId)
  }

  updateLink = file => {
    this.props.updateOption('link')(file.path)
  }

  removeLink = () => {
    this.props.updateOption('link')(null)
  }

  removeFileId = () => {
    this.props.updateOption('srcFileId')(null)
  }

  updateImageSize = (value, unit) => {
    if (!this.props.entity.options.ratio) {
      const ratio = this.getImageRatio()
      this.props.update({
        ...this.props.entity,
        options: {
          ...this.props.entity.options,
          ratio,
        },
        styles: {
          ...this.props.entity.styles,
          width: `${value}${unit}`,
        },
      })
    } else {
      this.props.updateStyle('width', unit)(value)
    }
  }

  updateImageMobileSize = (value, unit) => {
    if (!this.props.entity.options.ratio) {
      const ratio = this.getImageRatio()
      this.props.update({
        ...this.props.entity,
        options: {
          ...this.props.entity.options,
          ratio,
        },
        mobileStyles: {
          ...this.props.entity.mobileStyles,
          width: `${value}${unit}`,
        },
      })
    } else {
      this.props.updateMobileStyle('width', unit)(value)
    }
  }

  render() {
    const {
      entity,
      updateStyle,
      updateMobileStyle,
      updateOption,
      update,
      popups,
      isInstantUploadEnabled,
      t,
    } = this.props

    const popupChoiceList = {}

    popups.forEach(popup => {
      popupChoiceList[popup.id] = popup.options.title
    })

    return (
      <React.Fragment>
        <FileUpload
          directLink={entity.styles.backgroundImage}
          fileId={entity.options.srcFileId}
          afterUpload={this.updateImage}
          removeFile={this.removeFileId}
          mimeTypes={mimeTypes.image}
          fileType="image"
          label="image"
          instantUploadEnabled={isInstantUploadEnabled}
          onClose={this.props.disableInstantUpload}
        />
        <Select
          update={this.updateAction}
          choiceList={imageActionsNames}
          labelText="entity_settings.image.choose_action"
          value={entity.options.action || imageActionsKeys.openUrl}
        />
        {(!entity.options.action ||
          entity.options.action === imageActionsKeys.openUrl) && (
          <FileUpload
            directLink={entity.options.link}
            changeLinkHandler={updateOption('link')}
            afterUpload={this.updateLink}
            removeFile={this.removeLink}
            mimeTypes={mimeTypes.all}
            readonly={false}
            label="link"
            placeholder={t('entity_settings.image.choose_file_or_write_url')}
          />
        )}
        {entity.options.action === imageActionsKeys.showPopup && (
          <Select
            labelText="entity_settings.image.popup.label"
            update={this.updatePopup}
            choiceList={popupChoiceList}
            firstOption="entity_settings.image.popup.first_option"
            value={entity.options.popup}
            needTranslate={false}
          />
        )}
        <Checkbox
          labelText="entity_settings.image.target_label"
          update={updateOption('openNewTab')}
          value={entity.options.openNewTab}
        />
        <Input
          update={updateOption('alt')}
          labelText="alt"
          value={entity.options.alt}
        />
        <ImageSize
          attrId={entity.id}
          updateWidth={this.updateImageSize}
          updateMobileWidth={this.updateImageMobileSize}
          width={parseInt(entity.styles.width)}
          mobileWidth={entity.mobileStyles.width}
        />
        <FlexAlignStyle
          align={entity.styles.justifyContent}
          mobileAlign={entity.mobileStyles.justifyContent}
          mobileUpdate={updateMobileStyle('justifyContent')}
          update={updateStyle('justifyContent')}
        />
        <MarginsStyle
          marginTop={entity.styles.marginTop}
          marginRight={entity.styles.marginRight}
          marginBottom={entity.styles.marginBottom}
          marginLeft={entity.styles.marginLeft}
          mobileMarginTop={entity.mobileStyles.marginTop}
          mobileMarginRight={entity.mobileStyles.marginRight}
          mobileMarginBottom={entity.mobileStyles.marginBottom}
          mobileMarginLeft={entity.mobileStyles.marginLeft}
          update={updateStyle}
          mobileUpdate={updateMobileStyle}
        />
        <CornerStyle
          topLeft={entity.styles.borderTopLeftRadius}
          topRight={entity.styles.borderTopRightRadius}
          bottomRight={entity.styles.borderBottomRightRadius}
          bottomLeft={entity.styles.borderBottomLeftRadius}
          mobileTopLeft={entity.mobileStyles.borderTopLeftRadius}
          mobileTopRight={entity.mobileStyles.borderTopRightRadius}
          mobileBottomLeft={entity.mobileStyles.borderBottomLeftRadius}
          mobileBottomRight={entity.mobileStyles.borderBottomRightRadius}
          update={updateStyle}
          mobileUpdate={updateMobileStyle}
        />
        <GroupTitle>filters</GroupTitle>
        <Range
          labelText="blur"
          value={entity.options.blur}
          update={updateOption('blur')}
          max={10}
        />
        <ShadowStyle
          shadow={entity.styles.boxShadow}
          mobileShadow={entity.mobileStyles.boxShadow}
          update={updateStyle('boxShadow')}
          mobileUpdate={updateMobileStyle('boxShadow')}
        />
        <DelayOption
          update={updateOption('delay')}
          delay={entity.options.delay}
        />
        <DeviceAppearance update={update} entity={entity} />
        <Attributes update={updateOption} entity={entity} />
      </React.Fragment>
    )
  }
}

ImageSettings.propTypes = {
  updateStyle: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  entity: types.entity.isRequired,
  section: PropTypes.objectOf(PropTypes.any).isRequired,
  popups: PropTypes.arrayOf(PropTypes.object),
  isInstantUploadEnabled: PropTypes.bool.isRequired,
  disableInstantUpload: PropTypes.func.isRequired,
}

const mapStateToProps = ({ page, management }, { entity }) => ({
  section:
    getEntityAncestorByType(
      page.present.entities,
      entity,
      EntityTypeEnum.Section,
    ) ||
    getEntityAncestorByType(
      page.present.entities,
      entity,
      structureTypes.SECTION,
    ),
  popups: getByType(page.present, structureTypes.POPUP),
  isInstantUploadEnabled: isInstantUploadEnabled(management),
})

export default withTranslation('client')(
  connect(mapStateToProps, { disableInstantUpload })(ImageSettings),
)
