import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { buttonLinkTypeNames } from 'common/constants/ButtonLinkTypes'
import {
  redirectionTypes,
  sendFormRedirectionChoices,
} from 'common/constants/buttonTypes'
import { blogPageTypes, pageTypes } from 'common/constants/pageTypes'
import {
  buttonActionsTypes,
  buttonActionsNames,
} from 'common/constants/settings'
import { PageTypeEnum } from 'common/enums/PageTypeEnum'
import { useActions } from 'common/hooks/useActions'
import * as types from 'common/types'
import { addUrlSchemaToTheLink } from 'common/utils/urlUtils'
import * as pageActions from 'client/actions/pageActions'
import {
  Input,
  Select,
  Checkbox,
} from 'client/components/core/Sidebar/components/Settings/components'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import ColorPickerWithGradient from 'client/components/core/Sidebar/components/Settings/components/ColorPickerWithGradient'
import {
  UrlRedirect,
  DelayOption,
  FontSize,
  DeviceAppearance,
} from 'client/components/core/Sidebar/components/Settings/options'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import {
  BorderStyle,
  MarginsStyle,
  PaddingStyle,
  CornerStyle,
  ShadowStyle,
} from 'client/components/core/Sidebar/components/Settings/styles'
import { mimeTypes } from 'client/constants/editorSettings'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { AutomationRuleTriggersEnum } from 'client/enums/AutomationRulesEnum'
import { usePage } from 'client/store'
import { useAutomationRules } from 'client/store'
import {
  saveAutomationRule,
  saveDeletedAutomationRule,
} from 'client/store/automationRules/automationRulesActions'
import automationRulesSelectors from 'client/store/automationRules/automationRulesSelectors'
import pageSelectors from 'client/store/page/pageSelectors'
import { automationRuleTriggerEntityProperties } from 'client/types/AutomationRulesActionsInterface'
import { FileUpload } from '../../components'
import AutomationActionsList from '../../components/AutomationRulesSettings/AutomationActionsList'
import AutomationRulesActionsModal from '../../components/AutomationRulesSettings/AutomationRulesActionsModal'
import automationRulePageTypes from '../../components/AutomationRulesSettings/utils/automationRulesPageTypes'
import BlogLinkAction from '../../components/BlogLink'
import ButtonIconPicker from '../../components/ButtonIconPicker'
import TextFont from '../../components/Font/TextFont'
import HoverButtonSetting from '../../components/HoverButton'
import PrimaryButton from '../../components/PrimaryButton'
import Attributes from '../../options/Attributes'
import ButtonWidth from '../../styles/ButtonWidth'
import FlexAlignStyle from '../../styles/FlexAlignStyle'
import LineHeight from '../../styles/LineHeight'

// TODO FOR MIGRATION - button could have background or backgroundColor property or both of them
function ButtonSettings(props) {
  const {
    entity,
    update,
    updateStyle,
    updateMobileStyle,
    updateOption,
    updateMobileOption,
    updateOptions,
    updateMobileOptions,
    toggleOverlay,
    overlayVisible,
  } = props

  const { isMobile } = useDeviceModeContext()
  const { t } = useTranslation()
  const popups = usePage(pageSelectors.getPopupEntities)
  const pageType = usePage(pageSelectors.getPageType)
  const isTemplate = usePage(pageSelectors.isPageTemplate)
  const doubleOptIn = usePage(pageSelectors.getDoubleOptIn)
  const toggleDoubleOptInAction = useActions(pageActions.toggleDoubleOptIn)
  const [isAutomationsModalOpen, setIsAutomationsModalOpen] = useState(false)
  const originalButtonActions = useAutomationRules(state =>
    automationRulesSelectors.getOriginalAutomationRulesByButtonIdId(
      state,
      entity.id,
    ),
  )

  const editedButtonActions = useAutomationRules(state =>
    automationRulesSelectors.getEditedAutomationRulesByEntityId(
      state,
      entity.id,
      automationRuleTriggerEntityProperties.buttonId,
    ),
  )

  const dispatch = useDispatch()
  const updateAction = action => {
    const { entity } = props
    const popup =
      action !== buttonActionsTypes.showPopup ? '' : entity.options.popup

    const needResetRedirectionType =
      action === buttonActionsTypes.sendForm &&
      entity.options.action !== buttonActionsTypes.sendForm

    const newRedirectionType = blogPageTypes.includes(props.pageType)
      ? redirectionTypes.customUrl
      : redirectionTypes.nextStep

    const updatedEntity = {
      ...entity,
      options: {
        ...entity.options,
        action,
        redirectionType: needResetRedirectionType
          ? newRedirectionType
          : entity.options.redirectionType,
        popup,
      },
    }

    const needResetFile =
      action === buttonActionsTypes.downloadFile &&
      entity.options.action !== buttonActionsTypes.downloadFile
    if (needResetFile) {
      delete updatedEntity.options.fileId
    }

    const needResetTagId =
      action !== buttonActionsTypes.sendForm && !!entity.options.tagId

    if (needResetTagId) {
      delete updatedEntity.options.tagId
    }

    const needResetBlogLink =
      entity.options.action === buttonActionsTypes.blogLink &&
      action !== buttonActionsTypes.blogLink

    if (needResetBlogLink) {
      updatedEntity.blogLink = ''
    }

    const needResetAutomationRules = action !== buttonActionsTypes.sendForm
    // check if we have existing rules
    resetAutomationRules(
      needResetAutomationRules && editedButtonActions.length > 0,
    )
    props.update(updatedEntity)
  }

  const toggleDoubleOptIn = () => {
    toggleDoubleOptInAction()
  }

  const removeFileId = () => {
    const { entity } = props
    const updatedEntity = { ...entity }
    delete updatedEntity.options.srcFileId
    props.update(updatedEntity)
  }

  const updatePopup = popupId => {
    props.updateOption('popup')(popupId)
  }

  const toggleNoFollow = value => {
    let noFollow = ''
    if (value) {
      noFollow = 'nofollow'
    }
    props.updateOption('rel')(noFollow)
  }

  const updateBlogLink = value => {
    const { entity } = props
    const updatedEntity = {
      ...entity,
      options: {
        ...entity.options,
        blogLink: value,
      },
    }
    props.update(updatedEntity)
  }

  const updateRedirectionType = redirectionType => {
    const { entity } = props
    const updatedEntity = {
      ...entity,
      options: {
        ...entity.options,
        urlRedirect: '',
        redirectionType,
      },
    }
    props.update(updatedEntity)
  }
  const popupChoiceList = {}

  popups.forEach(popup => {
    popupChoiceList[popup.id] = popup.options.title
  })

  const resetAutomationRules = condition => {
    if (condition) {
      const automationRuleDeletedData = {
        automationTriggers: [
          {
            type: automationTriggerType,
            buttonId: entity.id,
          },
        ],
        automationActions: originalButtonActions,
      }
      const automationRuleData = {
        automationTriggers: [
          {
            type: automationTriggerType,
            buttonId: entity.id,
          },
        ],
        automationActions: [],
      }
      dispatch(saveDeletedAutomationRule(automationRuleDeletedData))
      dispatch(saveAutomationRule(automationRuleData))
    }
  }

  const isActionSendForm = entity.options.action === buttonActionsTypes.sendForm
  const isRedirectionTypeCustomUrl =
    entity.options.redirectionType === redirectionTypes.customUrl
  const isActionBlogLink = entity.options.action === buttonActionsTypes.blogLink

  let pageButtonActionsNames
  if (pageType === pageTypes.lecture) {
    pageButtonActionsNames = {
      [buttonActionsTypes.openUrl]: buttonActionsNames.openUrl,
      [buttonActionsTypes.lectureCompleteAndRedirect]:
        buttonActionsNames.lectureCompleteAndRedirect,
      [buttonActionsTypes.downloadFile]: buttonActionsNames.downloadFile,
    }
  } else {
    pageButtonActionsNames = { ...buttonActionsNames }
    delete pageButtonActionsNames.lectureCompleteAndRedirect
    delete pageButtonActionsNames.login
    delete pageButtonActionsNames.resetPassword
    delete pageButtonActionsNames.confirmRegistration
  }

  if (pageType === pageTypes.inline || pageType === pageTypes.popup) {
    delete pageButtonActionsNames.showPopup
  }

  if (pageType === pageTypes.inactiveFunnelPage) {
    delete pageButtonActionsNames.nextStepRedirection
  }

  if (blogPageTypes.includes(pageType)) {
    delete pageButtonActionsNames.nextStepRedirection
  }
  if (pageType === pageTypes.customLogin) {
    pageButtonActionsNames = {
      type: buttonActionsNames[entity.options.action],
    }
  }

  if (pageType === PageTypeEnum.NotFound) {
    pageButtonActionsNames = {
      [buttonActionsTypes.openUrl]: buttonActionsNames.openUrl,
      [buttonActionsTypes.downloadFile]: buttonActionsNames.downloadFile,
    }
  }

  if (pageType === PageTypeEnum.InfoPage) {
    pageButtonActionsNames = {
      [buttonActionsTypes.openUrl]: buttonActionsNames.openUrl,
      [buttonActionsTypes.showPopup]: buttonActionsNames.showPopup,
      [buttonActionsTypes.downloadFile]: buttonActionsNames.downloadFile,
    }
  }

  if (blogPageTypes.includes(pageType) === false) {
    delete pageButtonActionsNames.blogLink
  }

  let redirectionTypeNames = { ...sendFormRedirectionChoices }
  if (blogPageTypes.includes(pageType)) {
    delete redirectionTypeNames[redirectionTypes.nextStep]
  }

  const handleTextFontChange = fontProperties => {
    const updatedEntity = { ...entity }
    if (isMobile) {
      if (fontProperties.fontFileId) {
        delete updatedEntity.mobileStyles.fontWeight
        delete updatedEntity.mobileStyles.fontStyle
        update({
          ...updatedEntity,
          mobileStyles: {
            ...updatedEntity.mobileStyles,
            fontFamily: fontProperties.fontFamily,
          },
          mobileOptions: {
            ...updatedEntity.mobileOptions,
            textFontFileId: fontProperties.fontFileId,
          },
        })
      } else {
        if (
          updatedEntity.mobileOptions &&
          updatedEntity.mobileOptions.textFontFileId
        ) {
          delete updatedEntity.mobileOptions.textFontFileId
        }
        if (fontProperties.fontFamily === 'inherit') {
          delete updatedEntity.mobileStyles.fontWeight
          delete updatedEntity.mobileStyles.fontStyle
        }
        update({
          ...updatedEntity,
          mobileStyles: {
            ...updatedEntity.mobileStyles,
            ...fontProperties,
          },
        })
      }
    } else {
      if (fontProperties.fontFileId) {
        delete updatedEntity.styles.fontWeight
        delete updatedEntity.styles.fontStyle
        update({
          ...updatedEntity,
          styles: {
            ...updatedEntity.styles,
            fontFamily: fontProperties.fontFamily,
          },
          options: {
            ...updatedEntity.options,
            textFontFileId: fontProperties.fontFileId,
          },
        })
      } else {
        if (updatedEntity.options.textFontFileId) {
          delete updatedEntity.options.textFontFileId
        }
        if (fontProperties.fontFamily === 'inherit') {
          delete updatedEntity.styles.fontWeight
          delete updatedEntity.styles.fontStyle
        }
        update({
          ...updatedEntity,
          styles: {
            ...updatedEntity.styles,
            ...fontProperties,
          },
        })
      }
    }
  }

  const handleSubTextFontChange = fontProperties => {
    const updatedEntity = { ...entity }
    if (isMobile) {
      if (fontProperties.fontFileId) {
        delete updatedEntity.mobileOptions.subTextFontWeight
        delete updatedEntity.mobileOptions.subTextFontStyle
        update({
          ...updatedEntity,
          mobileOptions: {
            ...updatedEntity.mobileOptions,
            subTextFontFileId: fontProperties.fontFileId,
            subTextFontFamily: fontProperties.fontFamily,
          },
        })
      } else {
        if (
          updatedEntity.mobileOptions &&
          updatedEntity.mobileOptions.subTextFontFileId
        ) {
          delete updatedEntity.mobileOptions.subTextFontFileId
        }
        if (fontProperties.fontFamily === 'inherit') {
          delete updatedEntity.mobileOptions.subTextFontWeight
          delete updatedEntity.mobileOptions.subTextFontStyle
        }
        updateMobileOptions({
          ...updatedEntity.mobileOptions,
          subTextFontFamily: fontProperties.fontFamily,
          subTextFontStyle: fontProperties.fontStyle,
          subTextFontWeight: fontProperties.fontWeight,
        })
      }
    } else {
      if (fontProperties.fontFileId) {
        delete updatedEntity.options.subTextFontWeight
        delete updatedEntity.options.subTextFontStyle
        update({
          ...updatedEntity,
          options: {
            ...updatedEntity.options,
            subTextFontFileId: fontProperties.fontFileId,
            subTextFontFamily: fontProperties.fontFamily,
          },
        })
      } else {
        if (updatedEntity.options.subTextFontFileId) {
          delete updatedEntity.options.subTextFontFileId
        }
        if (fontProperties.fontFamily === 'inherit') {
          delete updatedEntity.options.subTextFontWeight
          delete updatedEntity.options.subTextFontStyle
        }
        updateOptions({
          ...updatedEntity.options,
          subTextFontFamily: fontProperties.fontFamily,
          subTextFontStyle: fontProperties.fontStyle,
          subTextFontWeight: fontProperties.fontWeight,
        })
      }
    }
  }

  const removeBackgroundColorProperty = () => {
    if (isMobile) {
      const updatedEntity = { ...entity }
      delete updatedEntity.mobileStyles.background
      delete updatedEntity.mobileStyles.backgroundColor
      update(updatedEntity)
    } else {
      const updatedEntity = { ...entity }
      delete updatedEntity.styles.background
      delete updatedEntity.styles.backgroundColor
      update(updatedEntity)
    }
  }

  const textFontFamily = isMobile
    ? entity.mobileStyles.fontFamily
    : entity.styles.fontFamily
  const textFontWeight = isMobile
    ? entity.mobileStyles.fontWeight
    : entity.styles.fontWeight
  const textFontStyle = isMobile
    ? entity.mobileStyles.fontStyle
    : entity.styles.fontStyle

  const subTextFontFamily = isMobile
    ? entity.mobileOptions.subTextFontFamily
    : entity.options.subTextFontFamily
  const subTextFontWeight = isMobile
    ? entity.mobileOptions.subTextFontWeight
    : entity.options.subTextFontWeight
  const subTextFontStyle = isMobile
    ? entity.mobileOptions.subTextFontStyle
    : entity.options.subTextFontStyle

  const automationTriggerType = blogPageTypes.includes(pageType)
    ? AutomationRuleTriggersEnum.blog_page_form_subscribed
    : AutomationRuleTriggersEnum.form_subscribed

  const isAutomationRulesVisible =
    !isTemplate &&
    automationRulePageTypes.includes(pageType) &&
    isActionSendForm

  useEffect(() => {
    if (
      props.entity.options.action === buttonActionsTypes.nextStepRedirection &&
      blogPageTypes.includes(props.pageType)
    ) {
      props.update({
        ...props.entity,
        options: {
          ...props.entity.options,
          action: buttonActionsTypes.sendForm,
          redirectionType: redirectionTypes.customUrl,
        },
      })
    }
  }, [])

  return (
    <React.Fragment>
      <Select
        update={updateAction}
        choiceList={pageButtonActionsNames}
        labelText="Button action"
        value={entity.options.action}
      />
      {entity.options.action === buttonActionsTypes.downloadFile && (
        <>
          <FileUpload
            fileId={entity.options.srcFileId}
            afterUpload={file => updateOption('srcFileId')(file.id)}
            removeFile={removeFileId}
            mimeTypes={mimeTypes.all}
          />
        </>
      )}
      {entity.options.action === buttonActionsTypes.showPopup && (
        <Select
          labelText="Popup"
          update={updatePopup}
          choiceList={popupChoiceList}
          firstOption="entity_settings.button.choose_actions"
          value={entity.options.popup}
          needTranslate={false}
        />
      )}
      {entity.options.action === buttonActionsTypes.openUrl && [
        <Input
          key="url"
          labelText="url"
          update={updateOption('linkUrl')}
          placeholder="entity_settings.button.url_input.placeholder"
          value={entity.options.linkUrl}
        />,
        <Select
          key="link-type"
          labelText="open in new window"
          update={updateOption('linkTarget')}
          choiceList={buttonLinkTypeNames}
          value={entity.options.linkTarget}
        />,
        <Checkbox
          key="noFollow"
          labelText="entity_settings.button.no_follow.label"
          helpMessage="entity_settings.button.no_follow.description"
          value={Boolean(entity.options.rel)}
          update={toggleNoFollow}
        />,
      ]}
      {isActionSendForm && (
        <Select
          labelText="entity_settings.button.send_form.redirection.label"
          value={entity.options.redirectionType}
          update={updateRedirectionType}
          dataTestElement="button-redirection-checkbox"
          choiceList={redirectionTypeNames}
        />
      )}
      {blogPageTypes.includes(pageType) && isActionBlogLink && (
        <React.Fragment>
          <BlogLinkAction
            update={updateBlogLink}
            blogLink={entity.options.blogLink}
            needOptionWrapper
          />
          <Select
            labelText="open in new window"
            update={updateOption('linkTarget')}
            choiceList={buttonLinkTypeNames}
            value={entity.options.linkTarget}
          />
          <Checkbox
            labelText="entity_settings.button.no_follow.label"
            helpMessage="entity_settings.button.no_follow.description"
            value={Boolean(entity.options.rel)}
            update={toggleNoFollow}
          />
        </React.Fragment>
      )}
      {isActionSendForm && isRedirectionTypeCustomUrl && (
        <UrlRedirect
          key="urlRedirect"
          urlRedirect={addUrlSchemaToTheLink(entity.options.urlRedirect)}
          update={updateOption('urlRedirect')}
          data-test-element="button-redirection-input"
        />
      )}
      {isActionSendForm && pageType !== pageTypes.webinarRegistration && (
        <Checkbox
          labelText="entity_settings.button.send_model.double_opt_in.label"
          update={toggleDoubleOptIn}
          value={doubleOptIn}
        />
      )}
      {isAutomationRulesVisible && [
        editedButtonActions.length > 0 && (
          <AutomationActionsList actions={editedButtonActions} />
        ),
        <PrimaryButton
          onClick={() => {
            setIsAutomationsModalOpen(true)
          }}
          key={'automationSettingsButton'}
        >
          {t('entity_settings.automation_rule.edit.add_automation_rule')}
        </PrimaryButton>,
        <AutomationRulesActionsModal
          key="automationSettingsModal"
          opened={isAutomationsModalOpen}
          triggerType={automationTriggerType}
          onClose={() => setIsAutomationsModalOpen(false)}
          actions={editedButtonActions}
          entityId={entity.id}
          triggerKey={'buttonId'}
        />,
      ]}
      <FlexAlignStyle
        align={entity.styles.alignSelf}
        mobileAlign={entity.mobileStyles.alignSelf}
        update={updateStyle('alignSelf')}
        mobileUpdate={updateMobileStyle('alignSelf')}
      />
      <ButtonWidth
        update={updateStyle('width')}
        mobileUpdate={updateMobileStyle('width')}
        width={entity.styles.width}
        mobileWidth={entity.mobileStyles.width}
      />
      <MarginsStyle
        marginTop={entity.styles.marginTop}
        marginRight={entity.styles.marginRight}
        marginBottom={entity.styles.marginBottom}
        marginLeft={entity.styles.marginLeft}
        mobileMarginTop={entity.mobileStyles.marginTop}
        mobileMarginRight={entity.mobileStyles.marginRight}
        mobileMarginBottom={entity.mobileStyles.marginBottom}
        mobileMarginLeft={entity.mobileStyles.marginLeft}
        update={updateStyle}
        mobileUpdate={updateMobileStyle}
      />
      <GroupTitle>typography</GroupTitle>
      <Input
        labelText="button text"
        value={entity.options.text}
        update={updateOption('text')}
      />
      <FontSize
        update={updateStyle('fontSize', 'px')}
        mobileUpdate={updateMobileStyle('fontSize', 'px')}
        fontSize={entity.styles.fontSize}
        mobileFontSize={entity.mobileStyles.fontSize}
      />
      <LineHeight
        lineHeight={entity.styles.lineHeight}
        mobileLineHeight={entity.mobileStyles.lineHeight}
        update={updateStyle('lineHeight', 'px')}
        mobileUpdate={updateMobileStyle('lineHeight', 'px')}
      />
      <TextFont
        fontFamily={textFontFamily}
        fontStyle={textFontStyle}
        fontWeight={textFontWeight}
        update={handleTextFontChange}
      />
      <Input
        labelText="entity_settings.button.sub_text.label"
        update={updateOption('subText')}
        value={entity.options.subText}
      />
      {entity.options.subText && (
        <FontSize
          labelText="entity_settings.button.sub_text.font_size.label"
          update={updateOption('subTextFontSize', 'px')}
          mobileUpdate={updateMobileOption('subTextFontSize', 'px')}
          fontSize={entity.options.subTextFontSize}
          mobileFontSize={entity.mobileOptions.subTextFontSize}
        />
      )}
      {entity.options.subText && (
        <TextFont
          labelText="entity_settings.button.sub_text.font_family.label"
          fontFamily={subTextFontFamily}
          fontStyle={subTextFontStyle}
          fontWeight={subTextFontWeight}
          update={handleSubTextFontChange}
        />
      )}
      <ButtonIconPicker
        valueBefore={entity.options.iconClassNameBefore}
        valueAfter={entity.options.iconClassNameAfter}
        updateBefore={updateOption('iconClassNameBefore')}
        updateAfter={updateOption('iconClassNameAfter')}
      />
      <GroupTitle>color</GroupTitle>
      <ColorPickerWithGradient
        label="background color"
        color={entity.styles.background || entity.styles.backgroundColor}
        mobileColor={
          entity.mobileStyles.background || entity.mobileStyles.backgroundColor
        }
        update={updateStyle('background')}
        mobileUpdate={updateMobileStyle('background')}
        removeBackgroundColorProperty={removeBackgroundColorProperty}
      />
      <ColorPicker
        label="text color"
        update={updateStyle('color')}
        mobileUpdate={updateMobileStyle('color')}
        color={entity.styles.color}
        mobileColor={entity.mobileStyles.color}
      />
      {entity.options.subText && (
        <ColorPicker
          label="entity_settings.button.sub_text.color.label"
          update={updateOption('subTextColor')}
          mobileUpdate={updateMobileOption('subTextColor')}
          color={entity.options.subTextColor}
          mobileColor={entity.mobileOptions.subTextColor}
        />
      )}
      <PaddingStyle
        mobilePadding={entity.mobileStyles.padding}
        padding={entity.styles.padding}
        update={updateStyle('padding')}
        mobileUpdate={updateMobileStyle('padding')}
      />
      <CornerStyle
        topLeft={entity.styles.borderTopLeftRadius}
        topRight={entity.styles.borderTopRightRadius}
        bottomRight={entity.styles.borderBottomRightRadius}
        bottomLeft={entity.styles.borderBottomLeftRadius}
        mobileTopLeft={entity.mobileStyles.borderTopLeftRadius}
        mobileTopRight={entity.mobileStyles.borderTopRightRadius}
        mobileBottomLeft={entity.mobileStyles.borderBottomLeftRadius}
        mobileBottomRight={entity.mobileStyles.borderBottomRightRadius}
        update={updateStyle}
        mobileUpdate={updateMobileStyle}
      />
      <ShadowStyle
        shadow={entity.styles.boxShadow}
        mobileShadow={entity.mobileStyles.boxShadow}
        update={updateStyle('boxShadow')}
        mobileUpdate={updateMobileStyle('boxShadow')}
      />
      <HoverButtonSetting
        textColor={entity.styles.color}
        backgroundColor={entity.styles.backgroundColor}
        subTextColor={entity.styles.subTextColor}
        update={updateOption('hover')}
        mobileUpdate={updateMobileOption('hover')}
        hover={entity.options.hover}
        mobileHover={entity.mobileOptions.hover}
      />
      <BorderStyle
        updateOption={updateOption}
        updateMobileOption={updateMobileOption}
        updateStyle={updateStyle}
        updateMobileStyle={updateMobileStyle}
        type={entity.options.borderType}
        style={entity.styles.borderStyle}
        width={entity.styles.borderWidth}
        color={entity.styles.borderColor}
        mobileType={entity.mobileOptions.borderType}
        mobileStyle={entity.mobileStyles.borderStyle}
        mobileWidth={entity.mobileStyles.borderWidth}
        mobileColor={entity.mobileStyles.borderColor}
        toggleOverlay={toggleOverlay}
        overlayVisible={overlayVisible}
      />
      <DelayOption
        update={updateOption('delay')}
        delay={entity.options.delay}
      />
      <DeviceAppearance entity={entity} update={update} />
      <Attributes entity={entity} update={updateOption} />
    </React.Fragment>
  )
}

ButtonSettings.propTypes = {
  toggleOverlay: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  updateStyle: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  updateMobileOption: PropTypes.func.isRequired,
  updateOptions: PropTypes.func.isRequired,
  entity: types.entity.isRequired,
  overlayVisible: PropTypes.bool.isRequired,
  popups: PropTypes.arrayOf(PropTypes.object),
  pageType: PropTypes.string.isRequired,
  doubleOptIn: PropTypes.bool,
}

export default ButtonSettings
