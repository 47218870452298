import React from 'react'
import structureTypes from 'common/constants/structureTypes'
import AgreementSettings from './entities/AgreementSettings'
import AudioSettings from './entities/AudioSettings'
import BlogPostContentPlaceholderSettings from './entities/BlogPostContentPlaceholderSettings'
import BulletListSettings from './entities/BulletListSettings'
import ButtonSettings from './entities/ButtonSettings'
import CarouselSettings from './entities/CarouselSettings'
import ContentBoxSettingsOld from './entities/ContentBoxSettingsOld'
import CountdownSettings from './entities/CountdownSettings'
import CouponSettings from './entities/CouponSettings'
import CustomerTypeSettings from './entities/CustomerTypeSettings'
import ExplicitConsentSettings from './entities/ExplicitConsentSettings'
import ImageSettingsNew from './entities/ImageSettings'
import InputSettings from './entities/InputSettings'
import LanguageSwitcherSettings from './entities/LanguageSwitcherSettings'
import OfferPricePlanSettings from './entities/OfferPricePlanSettings'
import OrderBumpSettings from './entities/OrderBumpSettings'
import PaymentButtonSettings from './entities/PaymentButtonSettings'
import PaymentMethodSettings from './entities/PaymentMethodSettings'
import PopupSettings from './entities/PopupSettings'
import RawHtmlSettings from './entities/RawHtmlSettings'
import RowSettingsOld from './entities/RowSettingsOld'
import SalesRedirectionButtonSettings from './entities/SalesRedirectionButtonSettings'
import SectionSettingsOld from './entities/SectionSettings/SectionSettingsOld'
import StartTimerSettings from './entities/StartTimerSettings'
import SurveySettings from './entities/SurveySettings'
import TextSettings from './entities/TextSettings'
import TwitterTweetButtonSettingsOld from './entities/TwitterTweetButtonSettingsOld'
import TwoStepPaymentForm from './entities/TwoStepPaymentFormSettings'
import WebinarCallToActionSettings from './entities/WebinarCallToActionSettings'
import WebinarSessionDateTimeSettings from './entities/WebinarSessionDateTimeSettings'
import WebinarSessionVideoSettings from './entities/WebinarSessionVideoSettings'

export default {
  create(type, id, props) {
    let component = null
    switch (type) {
      case structureTypes.SECTION:
        component = SectionSettingsOld
        break
      case structureTypes.ROW:
        component = RowSettingsOld
        break
      case structureTypes.TEXT:
        component = TextSettings
        break
      case structureTypes.BULLET_LIST:
        component = BulletListSettings
        break
      case structureTypes.BUTTON:
        component = ButtonSettings
        break
      case structureTypes.FORM_INPUT:
        component = InputSettings
        break
      case structureTypes.IMAGE:
        component = ImageSettingsNew
        break
      case structureTypes.POPUP:
        component = PopupSettings
        break
      case structureTypes.REMOTE_POPUP:
        component = PopupSettings
        break
      case structureTypes.AUDIO:
        component = AudioSettings
        break
      case structureTypes.CONTENT_BOX:
        component = ContentBoxSettingsOld
        break
      case structureTypes.WEBINAR_SESSION_DATE_TIME:
        component = WebinarSessionDateTimeSettings
        break
      case structureTypes.WEBINAR_SESSION_CALL_TO_ACTION:
        component = WebinarCallToActionSettings
        break
      case structureTypes.WEBINAR_SESSION_VIDEO:
        component = WebinarSessionVideoSettings
        break
      case structureTypes.PAYMENT_METHOD:
        component = PaymentMethodSettings
        break
      case structureTypes.CUSTOMER_TYPE:
        component = CustomerTypeSettings
        break
      case structureTypes.OFFER_PRICE:
        component = OfferPricePlanSettings
        break
      case structureTypes.RAW_HTML:
        component = RawHtmlSettings
        break
      case structureTypes.PAYMENT_BUTTON:
        component = PaymentButtonSettings
        break
      case structureTypes.ORDER_BUMP:
        component = OrderBumpSettings
        break
      case structureTypes.EXPLICIT_CONSENT:
        component = ExplicitConsentSettings
        break
      case structureTypes.START_TIMER:
        component = StartTimerSettings
        break
      case structureTypes.AGREEMENT:
        component = AgreementSettings
        break
      case structureTypes.COUNTDOWN:
        component = CountdownSettings
        break
      case structureTypes.CAROUSEL:
        component = CarouselSettings
        break
      case structureTypes.SALES_REDIRECTION_BUTTON:
        component = SalesRedirectionButtonSettings
        break
      case structureTypes.COUPON:
        component = CouponSettings
        break
      case structureTypes.BLOG_POST_CONTENT_PLACEHOLDER:
      case structureTypes.BLOG_CONTENT_PLACEHOLDER:
        component = BlogPostContentPlaceholderSettings
        break
      case structureTypes.LANGUAGE_SWITCHER:
        component = LanguageSwitcherSettings
        break
      case structureTypes.TWO_STEP_PAYMENT_FORM:
        component = TwoStepPaymentForm
        break
      case structureTypes.TWITTER_TWEET_BUTTON:
        component = TwitterTweetButtonSettingsOld
        break
      case structureTypes.SURVEY:
        component = SurveySettings
        break
      default:
        component = ''
    }

    return React.createElement(component, props)
  },
}
