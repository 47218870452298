import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Trans, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { NetworkError } from 'common/errors/NetworkError'
import * as fileActions from 'client/actions/fileActions'
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from 'client/components/core/Dialog'
import Error from 'client/components/core/Error'
import Tab from 'client/components/core/Tab'
import {
  getUserFilesByType,
  getUserFiles,
  getIsAllFilesFetched,
} from 'client/reducers/filesReducer'
import { isTemplate } from 'client/reducers/pageReducer'
import FileLibrary from './FileLibrary'
import FileUpload from './FileUpload'

const tabs = {
  upload: 'upload',
  my: 'my',
}

const StyledUl = styled.ul`
  margin: 0 auto;
  width: 400px;
  border-bottom: none;
  justify-content: space-around;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  align-content: stretch;
  font-size: 12px;
  color: #797f89;
  text-transform: uppercase;
  cursor: pointer;
  list-style-type: none;
`

const StyledInsert = styled.button`
  background-color: ${({ active }) => (active ? '#459DF5' : '#ccc')};
  border-radius: 5px;
  border: none;
  outline: none;
  color: #fff;
  padding: 10px 15px;
  margin-left: auto;
  font-size: 14px;
  cursor: ${({ active }) => (active ? 'pointer' : 'default')};
`

const getTitle = type => {
  let title = 'core.file_manager.title.your_files'

  if (type === 'image') {
    title = 'core.file_manager.title.your_images'
  } else if (type === 'video') {
    title = 'core.file_manager.title.your_video'
  } else if (type === 'audio') {
    title = 'core.file_manager.title.your_audio'
  }

  return title
}

class FileManager extends Component {
  state = {
    currentFile: null,
    currentTab: tabs.upload,
    userFiles: [],
    hasError: false,
  }

  async componentDidMount() {
    const isAllFilesNeeded =
      !this.props.fileType && !this.props.isAllFilesFetched

    if (!Object.keys(this.props.userFiles).length || isAllFilesNeeded) {
      if (isAllFilesNeeded) {
        this.props.setIsAllFilesFetched(true)
      }
      try {
        await this.props.fetchUserFiles(this.props.fileType)
      } catch (error) {
        if (error instanceof NetworkError) {
          // do nothing
        } else {
          window.Rollbar.error(error)
          this.setState({ hasError: true })
        }
      }
    }
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true })
    window.Rollbar.error(error, info)
  }

  chooseFile = file => () => this.setState({ currentFile: file })

  chooseTab = tab => () => this.setState({ currentTab: tab })

  insert = () => {
    if (!this.state.currentFile) {
      return
    }

    const { onInsert, closeFileManager } = this.props
    onInsert(this.state.currentFile)
    closeFileManager()
  }

  removeFile = fileId => async e => {
    e.preventDefault()
    e.stopPropagation()
    if (confirm(this.props.t('core.file_manager.confirm_remove_file'))) {
      await this.props.removeFile(fileId)
      if (this.props.afterRemove) {
        this.props.afterRemove(Number(fileId))
      }
    }
    if (
      this.state.currentFile &&
      this.state.currentFile.id === Number(fileId)
    ) {
      this.setState({ currentFile: null })
    }
  }

  afterUpload = file => {
    this.setState({
      currentFile: file,
      currentTab: tabs.my,
    })
    if (this.props.afterFileUpload) {
      this.props.afterFileUpload(file)
    }
  }

  render() {
    const {
      closeFileManager,
      userFiles,
      fileType,
      mimeTypes,
      isTemplate,
      getCustomFileName,
      validateUploadFile,
    } = this.props

    const { currentTab, currentFile } = this.state
    return (
      <Dialog close={closeFileManager} show id="filemanager">
        <DialogHeader close={closeFileManager}>
          {this.state.hasError && <Error />}
          <StyledUl>
            <Tab
              onClick={this.chooseTab(tabs.upload)}
              active={currentTab === tabs.upload}
            >
              <Trans parent={'span'}>core.file_manager.tabs.upload</Trans>
            </Tab>
            <Tab
              onClick={this.chooseTab(tabs.my)}
              active={currentTab === tabs.my}
            >
              <Trans parent="span">{getTitle(fileType)}</Trans>
            </Tab>
          </StyledUl>
        </DialogHeader>
        <DialogBody>
          <FileUpload
            show={currentTab === tabs.upload}
            mimeTypes={mimeTypes}
            afterUpload={this.afterUpload}
            validateUploadFile={validateUploadFile}
          />
          <FileLibrary
            files={userFiles}
            detailedFile={currentFile}
            onClick={this.chooseFile}
            show={currentTab === tabs.my}
            fileType={fileType}
            fetchData={this.props.fetchUserFiles}
            removeFile={this.removeFile}
            removingAllowed={!isTemplate}
            getCustomFileName={getCustomFileName}
          />
        </DialogBody>
        {currentTab !== tabs.upload && (
          <DialogFooter>
            <StyledInsert
              onClick={this.insert}
              active={!!this.state.currentFile}
            >
              <Trans parent="span">core.file_manager.insert</Trans>
            </StyledInsert>
          </DialogFooter>
        )}
      </Dialog>
    )
  }
}

FileManager.propTypes = {
  userFiles: PropTypes.objectOf(PropTypes.any),
  isAllFilesFetched: PropTypes.bool.isRequired,
  closeFileManager: PropTypes.func.isRequired,
  fetchUserFiles: PropTypes.func.isRequired,
  uploadFile: PropTypes.func.isRequired,
  onInsert: PropTypes.func.isRequired,
  mimeTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  fileType: PropTypes.string,
  t: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
  isTemplate: PropTypes.bool.isRequired,
  setIsAllFilesFetched: PropTypes.bool.isRequired,
  getCustomFileName: PropTypes.func,
  afterRemove: PropTypes.func,
  afterFileUpload: PropTypes.func,
  validateUploadFile: PropTypes.func,
}

FileManager.defaultProps = {
  userFiles: {},
  fileType: null,
}

const mapStateToProps = ({ files, page }, { fileType }) => ({
  userFiles: fileType
    ? getUserFilesByType(files, fileType)
    : getUserFiles(files),
  isTemplate: isTemplate(page),
  isAllFilesFetched: getIsAllFilesFetched(files),
})

const mapDispatchToProps = {
  fetchUserFiles: fileActions.fetchUserFiles,
  removeFile: fileActions.removeFile,
  setIsAllFilesFetched: fileActions.setIsAllFilesFetched,
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(FileManager),
)
