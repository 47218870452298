import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import * as types from 'common/types'
import { getHumanReadableSize } from 'client/utils/filesHelper'
import FileIcon from './FileIcon'

const StyledFileDetails = styled.div`
  width: 300px;
  padding-left: 30px;
  border-left: 1px solid #b1bacc;
`
const StyledImageContainer = styled.div`
  width: 100%;
  justify-content: center;
`
const StyledImageDescription = styled.div`
  padding-left: 20px;
`
const StyledImg = styled.img`
  width: 100%;
  max-height: 380px;
  object-fit: contain;
`

const FileDetails = ({ file, getCustomFileName }) => (
  <StyledFileDetails>
    <StyledImageContainer>
      {file.type === 'image' ? (
        <StyledImg src={file.path} />
      ) : file.type === 'video' ? (
        <video src={file.path} width={250} controls controlsList="nodownload" />
      ) : (
        <FileIcon fileName={file.filenameWithoutHash} fontSize={100} />
      )}
    </StyledImageContainer>
    <StyledImageDescription>
      <p>{getHumanReadableSize(file.size)}</p>
      <p>{file.filenameWithoutHash}</p>
      {getCustomFileName && <p>{getCustomFileName(file)}</p>}
    </StyledImageDescription>
  </StyledFileDetails>
)

FileDetails.propTypes = {
  file: types.file,
  getCustomFileName: PropTypes.func,
}

FileDetails.defaultProps = {
  file: null,
}
export default FileDetails
