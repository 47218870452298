import React, { useState } from 'react'
import CommonShippingFees from 'common/components/entities/ShippingFees'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import {
  SelectedShipping,
  ShippingFeesInterface,
} from 'common/types/entities/ShippingFeesInterface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import { mockedShippingResponse } from 'common/mocks/shipping'
import { generateBaseEntity } from 'client/utils/createStructureUtils'

type ShippingFeesProps = {
  entity: ShippingFeesInterface
  isMoveDownAllowed: boolean
  isMoveUpAllowed: boolean
}

const ShippingFees = ({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: ShippingFeesProps) => {
  const [selectedShipping, setSelectedShipping] =
    useState<SelectedShipping | null>(null)

  return (
    <BaseEntityNew
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <CommonShippingFees
        isLoading={false}
        selectedShipping={selectedShipping}
        onChange={setSelectedShipping}
        entity={entity}
        shipping={mockedShippingResponse}
      />
    </BaseEntityNew>
  )
}

export default ShippingFees

export const createShippingFees = (
  parentId: string,
  masterBlockId?: string,
): ShippingFeesInterface => {
  return {
    ...generateBaseEntity(EntityTypeEnum.ShippingFees, parentId, masterBlockId),
    type: EntityTypeEnum.ShippingFees,
    parentId: parentId,
    fontSize: 16,
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
  }
}
