import { CalculatePriceData } from 'common/types/shippingType'
import { transformPricingDto } from 'common/utils/productUtils'
import { calculatePrice } from 'client/api/shippingApi'

export const fetchOfferPricing = async (offers: CalculatePriceData[]) => {
  const { data } = await calculatePrice(offers)

  const offerPricings = data.map((offerPricing, index) => {
    const offer = offers[index]

    return transformPricingDto(
      {
        offerId: offer.offer,
        pricePlanId: offer.pricePlan,
        productId: offer.product,
      },
      offerPricing,
    )
  })

  return offerPricings
}
