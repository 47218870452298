import React from 'react'
import * as CommonMenu from 'common/components/entities/Menu'
import { MobileMenuTypesEnum } from 'common/components/entities/Menu/enums/MobileMenuTypesEnum'
import MenuInterface from 'common/components/entities/Menu/types/MenuInterface'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { useManagement } from 'client/store'
import managementSelectors from 'client/store/management/managementSelectors'
import { EntityProps } from 'client/types'
import { generateBaseEntity } from 'client/utils/createStructureUtils'

function Menu({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<MenuInterface>) {
  const { isMobile } = useDeviceModeContext()
  const isSideMenuOpened = useManagement(managementSelectors.isSideMenuOpened)
  const isDropDownMenuOpened = useManagement(
    managementSelectors.isDropDownMenuOpened,
  )
  const isEditing = useManagement(state =>
    managementSelectors.isEditing(state, entity.id),
  )

  const isSideMenuEmulationMode = isEditing && isSideMenuOpened
  const isDropDownMenuEmulationMode = isEditing && isDropDownMenuOpened

  return (
    <BaseEntityNew
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <>
        <CommonMenu.Horizontal
          attrId={entity.htmlAttrId}
          items={entity.menuItems}
          isVisibleOnMobile={!entity.burger}
          alignSelf={entity.alignSelf}
          mobileAlignSelf={entity.mobileAlignSelf}
          padding={entity.padding}
          mobilePadding={entity.mobilePadding}
          spacing={entity.spacing}
          mobileSpacing={entity.mobileSpacing}
          subMenuSpacing={entity.subMenuSpacing}
          fontSize={entity.fontSize}
          fontFamily={entity.fontFamily}
          fontWeight={entity.fontWeight}
          fontStyle={entity.fontStyle}
          mobileFontSize={entity.mobileFontSize}
          mobileFontFamily={entity.mobileFontFamily}
          mobileFontWeight={entity.mobileFontWeight}
          mobileFontStyle={entity.mobileFontStyle}
          lineHeight={entity.lineHeight}
          mobileLineHeight={entity.mobileLineHeight}
          color={entity.color}
          mobileColor={entity.mobileColor}
          subMenuColor={entity.subMenuColor}
          activeItemColor={entity.activeColor}
          mobileActiveItemColor={entity.mobileActiveColor}
          itemUnderlineColor={entity.itemUnderlineColor}
          mobileItemUnderlineColor={entity.mobileItemUnderlineColor}
          forceHide={
            (isMobile && isSideMenuEmulationMode) ||
            (isMobile && entity.burger) ||
            (isMobile && isDropDownMenuEmulationMode)
          }
          disablePointerEvents
          isEditing={isEditing}
        />
        {isMobile && (
          <React.Fragment>
            {entity.burger && (
              <CommonMenu.Burger
                burgerAlignSelf={entity.burgerAlignSelf}
                burgerIconColor={entity.burgerIconColor}
                padding={entity.padding}
                mobilePadding={entity.mobilePadding}
                opened={isSideMenuEmulationMode || isDropDownMenuEmulationMode}
                openedColor={
                  (entity.mobileMenuType === MobileMenuTypesEnum.side &&
                    entity.mobileColor) ||
                  entity.color
                }
                forceVisible={true}
              />
            )}
            {entity.burger &&
              entity.mobileMenuType === MobileMenuTypesEnum.side && (
                <CommonMenu.Side
                  attrId={entity.htmlAttrId}
                  menuAttrId={`side-${entity.htmlAttrId}`}
                  items={entity.menuItems}
                  opened={isSideMenuEmulationMode}
                  alignSelf={entity.alignSelf}
                  mobileAlignSelf={entity.mobileAlignSelf}
                  sideMenuBackgroundColor={entity.sideMenuBackgroundColor}
                  itemUnderlineColor={entity.itemUnderlineColor}
                  mobileItemUnderlineColor={entity.mobileItemUnderlineColor}
                  fontSize={entity.fontSize}
                  mobileFontSize={entity.mobileFontSize}
                  fontFamily={entity.fontFamily}
                  fontWeight={entity.fontWeight}
                  fontStyle={entity.fontStyle}
                  spacing={entity.mobileSpacing}
                  subMenuSpacing={entity.mobileSubMenuSpacing}
                  mobileFontFamily={entity.mobileFontFamily}
                  mobileFontWeight={entity.mobileFontWeight}
                  mobileFontStyle={entity.mobileFontStyle}
                  lineHeight={entity.lineHeight}
                  mobileLineHeight={entity.mobileLineHeight}
                  color={entity.color}
                  mobileColor={entity.mobileColor}
                  activeColor={entity.activeColor}
                  mobileActiveColor={entity.mobileActiveColor}
                  forceVisible={isSideMenuEmulationMode}
                  isEditing={isEditing}
                />
              )}
            {entity.burger &&
              entity.mobileMenuType === MobileMenuTypesEnum.dropDown && (
                <CommonMenu.DropDown
                  attrId={entity.htmlAttrId}
                  items={entity.menuItems}
                  alignSelf={entity.alignSelf}
                  mobileAlignSelf={entity.mobileAlignSelf}
                  itemUnderlineColor={entity.itemUnderlineColor}
                  mobileItemUnderlineColor={entity.mobileItemUnderlineColor}
                  fontSize={entity.fontSize}
                  spacing={entity.mobileSpacing}
                  subMenuSpacing={entity.mobileSubMenuSpacing}
                  mobileFontSize={entity.mobileFontSize}
                  fontFamily={entity.fontFamily}
                  fontWeight={entity.fontWeight}
                  fontStyle={entity.fontStyle}
                  mobileFontFamily={entity.mobileFontFamily}
                  mobileFontWeight={entity.mobileFontWeight}
                  mobileFontStyle={entity.mobileFontStyle}
                  lineHeight={entity.lineHeight}
                  mobileLineHeight={entity.mobileLineHeight}
                  color={entity.color}
                  mobileColor={entity.mobileColor}
                  activeColor={entity.activeColor}
                  mobileActiveColor={entity.mobileActiveColor}
                  forceVisible={isDropDownMenuEmulationMode}
                  isEditing={isEditing}
                />
              )}
          </React.Fragment>
        )}
      </>
    </BaseEntityNew>
  )
}

export function createMenu(parentId: string, masterBlockId?: string) {
  const menu: MenuInterface = {
    ...generateBaseEntity(EntityTypeEnum.Menu, parentId, masterBlockId),
    type: EntityTypeEnum.Menu,
    alignSelf: 'flex-start',
    mobileAlignSelf: 'flex-start',
    burgerAlignSelf: 'flex-end',
    spacing: 13,
    fontSize: 26,
    color: 'rgba(74, 74, 74, 1)',
    subMenuColor: 'rgba(74, 74, 74, 1)',
    activeColor: 'rgba(167, 154, 122, 1)',
    sideMenuBackgroundColor: 'rgba(255, 255, 255, 1)',
    burgerIconColor: 'rgba(74, 74, 74, 1)',
    mobileMenuType: MobileMenuTypesEnum.side,
    burger: true,
    menuItems: [
      {
        text: '',
        link: '',
        subMenuItems: [],
        subMenuOpen: false,
      },
      {
        text: '',
        link: '/contacts',
        subMenuItems: [],
        subMenuOpen: false,
      },
    ],
    padding: {
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
    },
    mobilePadding: {
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
    },
  }

  return menu
}

export default Menu
