import { useEffect, useRef, useState } from 'react'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'

const NARROW_WIDTH = 203
export const DEFAULT_WIDTH = 385
const WIDE_WIDTH = 431

const BORDERS_WIDTH = 4

function useExtendedMenu(
  extended: boolean,
) {
  const { isMobile } = useDeviceModeContext()
  const ref = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(
    extended ? WIDE_WIDTH : NARROW_WIDTH,
  )
  const [top, setTop] = useState(-30)

  useEffect(() => {
    if (width && ref.current) {
      setTop(-ref.current.offsetHeight)
    }
  }, [width])

  useEffect(() => {
    if (!ref.current || !ref.current.parentElement) {
      return
    }

    const parentWidth = ref.current.parentElement.offsetWidth

    setWidth(parentWidth + BORDERS_WIDTH)
  }, [isMobile])

  return { width, ref, top }
}
export default useExtendedMenu
