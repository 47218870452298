import { FieldSlugEnum } from 'common/enums/FieldSlugEnum'
import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'

export const paymentProcessorsRequiredFields: Record<
  PaymentMethodEnum,
  FieldSlugEnum[]
> = {
  [PaymentMethodEnum.StripeCard]: [FieldSlugEnum.Email],
  [PaymentMethodEnum.StripeIDeal]: [
    FieldSlugEnum.Email,
    FieldSlugEnum.FirstName,
  ],
  [PaymentMethodEnum.StripeSepaDebit]: [FieldSlugEnum.Email],
  [PaymentMethodEnum.StripeBancontact]: [
    FieldSlugEnum.Email,
    FieldSlugEnum.FirstName,
  ],
  [PaymentMethodEnum.PaypalSoap]: [FieldSlugEnum.Email],
  [PaymentMethodEnum.PaypalRest]: [FieldSlugEnum.Email],
  [PaymentMethodEnum.DlocalCard]: [
    FieldSlugEnum.Email,
    FieldSlugEnum.TaxNumber,
  ],
  [PaymentMethodEnum.DlocalBoleto]: [
    FieldSlugEnum.Email,
    FieldSlugEnum.FirstName,
    FieldSlugEnum.Surname,
    FieldSlugEnum.TaxNumber,
  ],
  [PaymentMethodEnum.DlocalPix]: [FieldSlugEnum.Email, FieldSlugEnum.TaxNumber],
  [PaymentMethodEnum.MercadoPagoCard]: [FieldSlugEnum.Email],
  [PaymentMethodEnum.MercadoPagoPix]: [FieldSlugEnum.Email],
  [PaymentMethodEnum.MercadoPagoBrCard]: [
    FieldSlugEnum.Email,
    FieldSlugEnum.TaxNumber,
  ],
  [PaymentMethodEnum.MercadoPagoBrPix]: [
    FieldSlugEnum.Email,
    FieldSlugEnum.TaxNumber,
  ],
  [PaymentMethodEnum.MercadoPagoBrBoleto]: [
    FieldSlugEnum.Email,
    FieldSlugEnum.FirstName,
    FieldSlugEnum.Surname,
    FieldSlugEnum.TaxNumber,
  ],
  [PaymentMethodEnum.MercadoPagoCoCard]: [
    FieldSlugEnum.Email,
    FieldSlugEnum.TaxNumber,
  ],
  [PaymentMethodEnum.MercadoPagoMxCard]: [
    FieldSlugEnum.Email,
    FieldSlugEnum.TaxNumber,
  ],
  [PaymentMethodEnum.MercadoPagoArCard]: [
    FieldSlugEnum.Email,
    FieldSlugEnum.TaxNumber,
  ],
  [PaymentMethodEnum.MercadoPagoClCard]: [
    FieldSlugEnum.Email,
    FieldSlugEnum.TaxNumber,
  ],
  [PaymentMethodEnum.MercadoPagoBoleto]: [FieldSlugEnum.Email],
  [PaymentMethodEnum.RazorpayCard]: [FieldSlugEnum.Email],
  [PaymentMethodEnum.Flutterwave]: [FieldSlugEnum.Email],
  [PaymentMethodEnum.Mollie]: [FieldSlugEnum.Email],
  [PaymentMethodEnum.Gocardless]: [FieldSlugEnum.Email],
  [PaymentMethodEnum.XenditIdCard]: [
    FieldSlugEnum.Email,
    FieldSlugEnum.FirstName,
    FieldSlugEnum.Surname,
    FieldSlugEnum.PhoneNumber,
  ],
  [PaymentMethodEnum.XenditPhCard]: [
    FieldSlugEnum.Email,
    FieldSlugEnum.FirstName,
    FieldSlugEnum.Surname,
    FieldSlugEnum.PhoneNumber,
  ],
  [PaymentMethodEnum.CashOnDelivery]: [],
  [PaymentMethodEnum.XenditIdDd]: [],
  [PaymentMethodEnum.XenditIdEwallet]: [],
  [PaymentMethodEnum.XenditPhDd]: [],
  [PaymentMethodEnum.XenditPhEwallet]: [],
  [PaymentMethodEnum.PaystackGhCard]: [],
  [PaymentMethodEnum.PaystackKeCard]: [],
  [PaymentMethodEnum.PaystackNgCard]: [],
  [PaymentMethodEnum.PaystackZaCard]: [],
  [PaymentMethodEnum.SecureAndPay]: [
    FieldSlugEnum.Email,
    FieldSlugEnum.FirstName,
    FieldSlugEnum.Surname,
  ],
}
