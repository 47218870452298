import React, { createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Fonts, getFontPropertiesFromFontFiles } from 'common/utils/fontsUtils'
import * as fileApi from 'client/api/fileApi'
import { DataFile } from 'client/store/files/filesReducer'

type CustomFontsContextType = {
  userFonts: Fonts | []
  userFontFiles: Record<number, DataFile>
  setUserFonts: (userFonts: Fonts) => void
  setUserFontFiles: (userFontFiles: Record<number, DataFile>) => void
  isFetching: boolean
}

const CustomFontsContext = createContext<CustomFontsContextType>({
  userFonts: [],
  userFontFiles: {},
  setUserFonts: () => {},
  setUserFontFiles: () => {},
  isFetching: false,
})

export const useCustomFonts = () => useContext(CustomFontsContext)

function CustomFontsProvider(props: React.PropsWithChildren<React.ReactNode>) {
  const [userFonts, setUserFonts] = useState<Fonts | []>([])
  const [userFontFiles, setUserFontFiles] = useState<Record<number, DataFile>>(
    {},
  )
  const [isFetching, setIsFetching] = useState(false)
  const { t } = useTranslation()
  useEffect(() => {
    async function fetchCustomFonts() {
      try {
        setIsFetching(true)
        const response = await fileApi.fetchUserFontFiles()
        const filteredFontFilesMeta = await getFontPropertiesFromFontFiles(
          Object.values(response.data.files),
        )
        setUserFontFiles(response.data.files)
        setUserFonts(filteredFontFilesMeta)
        setIsFetching(false)
      } catch (e) {
        setIsFetching(false)
        // do nothing
      }
    }
    fetchCustomFonts()
  }, [])

  return (
    <CustomFontsContext.Provider
      value={{
        userFonts,
        setUserFonts,
        userFontFiles,
        setUserFontFiles,
        isFetching,
      }}
    >
      {props.children}
    </CustomFontsContext.Provider>
  )
}

export default CustomFontsProvider
