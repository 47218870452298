import styled from 'styled-components'

const LanguageSwitcherItemUi = styled.div`
  font-size: 18px;
  float: left;
  margin: 6px;
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? '#149efc' : '#6b7c93')};
`

export default LanguageSwitcherItemUi
