import React from 'react'
import MenuUi from './ui/MenuUi'
import useExtendedMenu from './useExtendedMenu'

type MenuProps = {
  children: React.ReactNode
  extended: boolean
}

const Menu = ({ extended, children }: MenuProps) => {
  const { width, ref, top } = useExtendedMenu(extended)

  return (
    <MenuUi ref={ref} width={width} top={top}>
      {children}
    </MenuUi>
  )
}

export default Menu
