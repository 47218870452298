import React, { useEffect, useState } from 'react'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { Checkbox } from '../components'
import Range from '../components/Range/Range'
import MobileIconUi from '../styles/ui/MobileIconUi'

type ImageSizeProps = {
  width?: number
  mobileWidth?: number | string
  updateWidth: (width: number, unit?: string) => void
  updateMobileWidth: (width: number | string, unit?: string) => void
  attrId: string
}

function ImageSize({
  width = 100,
  mobileWidth,
  updateWidth,
  updateMobileWidth,
  attrId,
}: ImageSizeProps) {
  const { isMobile } = useDeviceModeContext()
  const [maxSize, setMaxSize] = useState(0)

  useEffect(() => {
    const imageWrapper = document.getElementById(attrId)
    setMaxSize(
      imageWrapper
        ? parseFloat(imageWrapper.getBoundingClientRect().width.toFixed(0))
        : 0,
    )
  }, [isMobile, attrId])

  const isMobileWidthResponsive = mobileWidth === '100%'

  if (isMobile && isMobileWidthResponsive) {
    return (
      // @ts-ignore
      <Checkbox
        labelText="entity_settings.image.responsive.label"
        update={() =>
          updateMobileWidth(width < maxSize ? width : maxSize, 'px')
        }
        value={true}
      />
    )
  }

  return (
    <>
      {isMobile && (
        // @ts-ignore
        <Checkbox
          labelText="entity_settings.image.responsive.label"
          update={() => updateMobileWidth('100%')}
          value={false}
        />
      )}
      {isMobile ? (
        <Range
          label="entity_settings.image.size"
          labelIcon={isMobile ? <MobileIconUi /> : null}
          value={
            mobileWidth
              ? typeof mobileWidth === 'string'
                ? parseInt(mobileWidth)
                : mobileWidth
              : width
          }
          change={val => updateMobileWidth(val, 'px')}
          max={maxSize}
        />
      ) : (
        <Range
          label="entity_settings.image.size"
          value={width}
          change={val => updateWidth(val, 'px')}
          max={maxSize}
        />
      )}
    </>
  )
}

export default ImageSize
