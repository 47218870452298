import LibraryElementEnum from '../../client/enums/LibraryElementEnum'
import EntityTypeEnum from '../enums/entityTypeEnum'
import { redirectionTypes } from './buttonTypes'
import { slugs } from './inputTypes'
import { buttonActionsTypes } from './settings'
import structureTypes from './structureTypes'
import toolBoxTypes from './toolBoxTypes'

const createChildlessStructure = (type, styles, options) => ({
  [type]: {
    ...(styles ? { styles } : {}),
    ...(options ? { options } : {}),
  },
})

const formStructure = {
  [EntityTypeEnum.Row]: {
    children: [
      {
        [EntityTypeEnum.Column]: {
          children: [
            {
              [structureTypes.TEXT]: {
                styles: {
                  fontSize: '35px',
                  textAlign: 'center',
                  fontFamily: 'Raleway',
                  lineHeight: '45px',
                },
                options: {
                  appearance: {
                    desktop: true,
                    mobile: true,
                  },
                  rawContentState:
                    '{"blocks":[{"key":"7gjsu","text":"entities.text.subscribe_text","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                },
              },
            },
            {
              [structureTypes.FORM_INPUT]: {
                options: {
                  inputType: 'text',
                  placeholder: 'email',
                  slug: 'email',
                  appearance: {
                    desktop: true,
                    mobile: true,
                  },
                },
              },
            },
            {
              [structureTypes.BUTTON]: {
                styles: {
                  backgroundColor: 'rgba(74, 144, 226, 1)',
                  borderBottomLeftRadius: '0px',
                  borderBottomRightRadius: '0px',
                  borderTopLeftRadius: '0px',
                  borderTopRightRadius: '0px',
                  color: 'rgba(255, 255, 255, 1)',
                  fontFamily: 'Raleway',
                  fontSize: '24px',
                  marginTop: '15px',
                  padding: '6px 100px 7px 100px',
                  width: '100%',
                },
                mobileStyles: {
                  padding: '5px 5px 5px 5px',
                },
                options: { redirectionType: redirectionTypes.customUrl },
              },
            },
            {
              [structureTypes.TEXT]: {
                styles: {
                  backgroundColor: 'rgba(155, 155, 155, 0)',
                  fontSize: '15px',
                  marginTop: '10px',
                  textAlign: 'center',
                },
                options: {
                  appearance: {
                    desktop: true,
                    mobile: true,
                  },
                  rawContentState:
                    '{"blocks":[{"key":"7gjsu","text":"entities.text.hate_spam","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                },
              },
            },
          ],
        },
      },
    ],
  },
}

const layout12Structure = {
  [EntityTypeEnum.Row]: {
    children: [createChildlessStructure(EntityTypeEnum.Column)],
  },
}

const mapToolBoxToStructure = {
  [toolBoxTypes.TOOLBOX_POPUP]: {
    [structureTypes.POPUP]: {
      children: [formStructure],
      styles: {
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '`6px',
        borderBottomRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        padding: '30px',
        borderColor: 'rgba(74, 144, 226, 1)',
        borderStyle: 'solid',
        borderWidth: '3px',
        boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2)',
      },
    },
  },
  [toolBoxTypes.TOOLBOX_POPUP_AGREEMENT]: {
    [structureTypes.POPUP]: {
      children: [
        {
          [EntityTypeEnum.Row]: {
            children: [
              {
                [EntityTypeEnum.Column]: {
                  children: [
                    createChildlessStructure(structureTypes.AGREEMENT),
                  ],
                },
              },
            ],
          },
        },
      ],
      styles: {
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '`6px',
        borderBottomRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        padding: '30px',
        borderColor: 'rgba(74, 144, 226, 1)',
        borderStyle: 'solid',
        borderWidth: '3px',
        boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2)',
      },
    },
  },
  [toolBoxTypes.TOOLBOX_POPUP_WEBINAR_REGISTRATION]: {
    [structureTypes.POPUP]: {
      children: [
        {
          [EntityTypeEnum.Row]: {
            children: [
              {
                [EntityTypeEnum.Column]: {
                  children: [
                    {
                      [structureTypes.FORM_INPUT]: {
                        options: {
                          inputType: 'text',
                          placeholder: 'email',
                          slug: 'email',
                          appearance: {
                            desktop: true,
                            mobile: true,
                          },
                        },
                      },
                    },
                    createChildlessStructure(
                      structureTypes.WEBINAR_REGISTRATION_DATE,
                    ),
                    {
                      [structureTypes.BUTTON]: {
                        styles: {
                          backgroundColor: 'rgba(74, 144, 226, 1)',
                          borderBottomLeftRadius: '0px',
                          borderBottomRightRadius: '0px',
                          borderTopLeftRadius: '0px',
                          borderTopRightRadius: '0px',
                          color: 'rgba(255, 255, 255, 1)',
                          fontFamily: 'Raleway',
                          fontSize: '24px',
                          marginTop: '15px',
                          padding: '6px 100px 7px 100px',
                          width: '100%',
                        },
                        mobileStyles: {
                          padding: '5px 5px 5px 5px',
                        },
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
      styles: {
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '`6px',
        borderBottomRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        padding: '30px',
        borderColor: 'rgba(74, 144, 226, 1)',
        borderStyle: 'solid',
        borderWidth: '3px',
        boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2)',
      },
    },
  },
  [toolBoxTypes.TOOLBOX_AUDIO]: createChildlessStructure(structureTypes.AUDIO),
  [toolBoxTypes.TOOLBOX_IMAGE]: createChildlessStructure(structureTypes.IMAGE),
  [toolBoxTypes.TOOLBOX_FORM_INPUT]: createChildlessStructure(
    structureTypes.FORM_INPUT,
  ),
  [toolBoxTypes.TOOLBOX_WEBINAR_SESSION_DATE_TIME]: createChildlessStructure(
    structureTypes.WEBINAR_SESSION_DATE_TIME,
  ),

  [toolBoxTypes.TOOLBOX_WEBINAR_SESSION_CALL_TO_ACTION]:
    createChildlessStructure(structureTypes.WEBINAR_SESSION_CALL_TO_ACTION),
  [toolBoxTypes.TOOLBOX_BUTTON]: createChildlessStructure(
    structureTypes.BUTTON,
  ),
  [toolBoxTypes.TOOLBOX_BLOG_BUTTON]: {
    [structureTypes.BUTTON]: {
      options: {
        action: buttonActionsTypes.sendForm,
        redirectionType: redirectionTypes.customUrl,
      },
    },
  },
  [toolBoxTypes.TOOLBOX_OPEN_URL_BUTTON]: createChildlessStructure(
    structureTypes.BUTTON,
    {},
    {
      action: buttonActionsTypes.openUrl,
    },
  ),
  [toolBoxTypes.TOOLBOX_TEXT]: createChildlessStructure(structureTypes.TEXT),
  [toolBoxTypes.TOOLBOX_BULLET_LIST]: createChildlessStructure(
    structureTypes.BULLET_LIST,
  ),
  [LibraryElementEnum.LibrarySection]: {
    [EntityTypeEnum.Section]: {
      children: [layout12Structure],
    },
  },
  [toolBoxTypes.TOOLBOX_CONTENT_BOX]: {
    [structureTypes.CONTENT_BOX]: {
      children: [layout12Structure],
    },
  },
  [toolBoxTypes.TOOLBOX_PAYMENT_METHOD]: createChildlessStructure(
    structureTypes.PAYMENT_METHOD,
  ),
  [toolBoxTypes.TOOLBOX_CUSTOMER_TYPE]: createChildlessStructure(
    structureTypes.CUSTOMER_TYPE,
  ),
  [toolBoxTypes.TOOLBOX_OFFER_PRICE]: createChildlessStructure(
    structureTypes.OFFER_PRICE,
  ),
  [toolBoxTypes.TOOLBOX_RAW_HTML]: createChildlessStructure(
    structureTypes.RAW_HTML,
  ),
  [toolBoxTypes.TOOLBOX_PAYMENT_BUTTON]: createChildlessStructure(
    structureTypes.PAYMENT_BUTTON,
  ),
  [toolBoxTypes.TOOLBOX_ORDER_BUMP]: {
    [structureTypes.ORDER_BUMP]: {
      children: [
        {
          [EntityTypeEnum.Row]: {
            children: [
              {
                [EntityTypeEnum.Column]: {
                  children: [createChildlessStructure(structureTypes.TEXT)],
                },
              },
            ],
          },
        },
      ],
    },
  },
  [toolBoxTypes.TOOLBOX_SALES_PHYSICAL_PRODUCT]: {
    [EntityTypeEnum.Row]: {
      children: [
        {
          [EntityTypeEnum.Column]: {
            children: [
              createChildlessStructure(structureTypes.PHYSICAL_PRODUCT),
              createChildlessStructure(structureTypes.SALES_REDIRECTION_BUTTON),
            ],
          },
        },
      ],
    },
  },
  [toolBoxTypes.TOOLBOX_PAYMENT_PHYSICAL_PRODUCT]: createChildlessStructure(
    structureTypes.PHYSICAL_PRODUCT,
  ),
  [toolBoxTypes.TOOLBOX_SALES_REDIRECTION_BUTTON]: createChildlessStructure(
    structureTypes.SALES_REDIRECTION_BUTTON,
  ),
  [toolBoxTypes.TOOLBOX_EXPLICIT_CONSENT]: createChildlessStructure(
    structureTypes.EXPLICIT_CONSENT,
  ),
  [toolBoxTypes.TOOLBOX_START_TIMER]: createChildlessStructure(
    structureTypes.START_TIMER,
  ),
  [toolBoxTypes.TOOLBOX_AGREEMENT]: createChildlessStructure(
    structureTypes.AGREEMENT,
  ),
  [toolBoxTypes.TOOLBOX_COUPON]: {
    [EntityTypeEnum.Row]: {
      children: [
        {
          [EntityTypeEnum.Column]: {
            children: [createChildlessStructure(structureTypes.COUPON)],
          },
        },
      ],
    },
  },
  [toolBoxTypes.TOOLBOX_BLOG_POST_BODY]: createChildlessStructure(
    structureTypes.BLOG_POST_CONTENT_PLACEHOLDER,
  ),
  [toolBoxTypes.TOOLBOX_BLOG_CONTENT_PLACEHOLDER]: createChildlessStructure(
    structureTypes.BLOG_CONTENT_PLACEHOLDER,
  ),
  [toolBoxTypes.TOOLBOX_LANGUAGE_SWITCHER]: createChildlessStructure(
    structureTypes.LANGUAGE_SWITCHER,
  ),
  [toolBoxTypes.TOOLBOX_TWO_STEP_PAYMENT_FORM]: {
    [structureTypes.TWO_STEP_PAYMENT_FORM]: {
      children: [
        {
          [structureTypes.TWO_STEP_PAYMENT_FORM_STEP_OPT_IN]: {
            children: [
              {
                [EntityTypeEnum.Row]: {
                  options: {
                    padding: {
                      paddingTop: 5,
                      paddingBottom: 5,
                      paddingLeft: 0,
                      paddingRight: 0,
                    },
                  },
                  children: [
                    {
                      [EntityTypeEnum.Column]: {
                        options: {
                          size: 4,
                        },
                        children: [
                          {
                            [structureTypes.FORM_INPUT]: {
                              styles: {
                                marginTop: '0px',
                              },
                              options: {
                                placeholder:
                                  'entities.field.first_name.default_placeholder',
                                slug: slugs.FIRST_NAME,
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      [EntityTypeEnum.Column]: {
                        options: {
                          size: 8,
                        },
                        children: [
                          {
                            [structureTypes.FORM_INPUT]: {
                              styles: {
                                marginTop: '0px',
                              },
                              options: {
                                placeholder:
                                  'entities.field.last_name.default_placeholder',
                                slug: slugs.SURNAME,
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                [EntityTypeEnum.Row]: {
                  options: {
                    padding: {
                      paddingTop: 5,
                      paddingBottom: 5,
                      paddingLeft: 0,
                      paddingRight: 0,
                    },
                  },
                  children: [
                    {
                      [EntityTypeEnum.Column]: {
                        children: [
                          {
                            [structureTypes.FORM_INPUT]: {
                              styles: {
                                marginTop: '0',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderColor: 'rgba(176, 186, 202, 1)',
                              },
                              options: {
                                inputType: 'text',
                                placeholder:
                                  'entities.field.email.default_placeholder',
                                slug: slugs.EMAIL,
                                appearance: {
                                  desktop: true,
                                  mobile: true,
                                },
                              },
                            },
                          },
                          {
                            [structureTypes.TEXT]: {
                              styles: {
                                textAlign: 'left',
                                marginTop: '10px',
                                fontSize: '12px',
                                marginLeft: '2px',
                              },
                              options: {
                                rawContentState:
                                  '{"blocks":[{"key":"7gjsu","text":"entities.two_step_payment_form.step_opt_in.default_shipping_text","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
                              },
                            },
                          },
                          {
                            [structureTypes.FORM_INPUT]: {
                              styles: {
                                marginTop: '10px',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderColor: 'rgba(176, 186, 202, 1)',
                              },
                              options: {
                                placeholder:
                                  'entities.field.street_address.default_placeholder',
                                slug: slugs.STREET_ADDRESS,
                              },
                            },
                          },
                          {
                            [structureTypes.FORM_INPUT]: {
                              styles: {
                                marginTop: '10px',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderColor: 'rgba(176, 186, 202, 1)',
                              },
                              options: {
                                placeholder:
                                  'entities.field.city.default_placeholder',
                                slug: slugs.CITY,
                              },
                            },
                          },
                          {
                            [structureTypes.FORM_INPUT]: {
                              styles: {
                                marginTop: '10px',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderColor: 'rgba(176, 186, 202, 1)',
                              },
                              options: {
                                slug: slugs.COUNTRY,
                              },
                            },
                          },
                          {
                            [structureTypes.FORM_INPUT]: {
                              styles: {
                                marginTop: '10px',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderColor: 'rgba(176, 186, 202, 1)',
                              },
                              options: {
                                placeholder:
                                  'entities.field.post_code.default_placeholder',
                                slug: slugs.POSTCODE,
                              },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
        {
          [structureTypes.TWO_STEP_PAYMENT_FORM_STEP_PAYMENT]: {
            children: [
              {
                [EntityTypeEnum.Row]: {
                  children: [
                    {
                      [EntityTypeEnum.Column]: {
                        children: [
                          createChildlessStructure(EntityTypeEnum.OfferPrice),
                          createChildlessStructure(
                            structureTypes.PAYMENT_METHOD,
                          ),
                        ],
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
      ],
    },
  },
  // [toolBoxTypes.TOOLBOX_CAROUSEL]: {
  //   [EntityTypeEnum.Carousel]: createCarousel(),
  // },
}

export const getContainerEntityStructure = type => {
  switch (type) {
    case structureTypes.BLOG_POST_BODY:
    case structureTypes.BLOG_PAGE_BODY:
    case structureTypes.BLOG_POST_LAYOUT_BODY:
    case structureTypes.BODY:
      return [EntityTypeEnum.Section, EntityTypeEnum.Row, EntityTypeEnum.Column]
    case EntityTypeEnum.Section:
      return [EntityTypeEnum.Row, EntityTypeEnum.Column]
    case EntityTypeEnum.Row:
      return [EntityTypeEnum.Column]
    default:
      return []
  }
}

export const getMovableEntityStructure = type => {
  switch (type) {
    case EntityTypeEnum.Section:
    case structureTypes.SECTION:
      return []
    case EntityTypeEnum.Row:
    case structureTypes.ROW:
      return [EntityTypeEnum.Section, structureTypes.SECTION]
    default:
      return [
        EntityTypeEnum.Section,
        structureTypes.SECTION,
        EntityTypeEnum.Row,
        structureTypes.ROW,
        EntityTypeEnum.Column,
        structureTypes.COLUMN,
      ]
  }
}

export default mapToolBoxToStructure
