import {
  FETCH_PAGE_FILES,
  FETCH_USER_FILES,
  SUCCESS,
  UPLOAD_FILE,
  REMOVE_FILE,
  SET_IS_ALL_FILES_FETCHED,
  ADD_FILE_TO_PAGE_FILES,
} from '../actionTypes'
import { ADD_FILES, SET_FILES } from '../store/files/filesActionTypes'
import { FETCH_SEARCH_USER_FILES } from '../store/files/filesActionTypes'

const defaultState = {
  pageFiles: {},
  userFiles: {},
  isAllFilesFetched: false,
}

function removeFile(files, removableFileId) {
  return Object.values(files)
    .filter(file => file.id !== removableFileId)
    .reduce((acc, file) => ({ ...acc, [file.id]: file }), {})
}

export default function (state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case FETCH_PAGE_FILES:
      return {
        ...state,
        pageFiles: {
          ...state.pageFiles,
          ...payload,
        },
      }
    case UPLOAD_FILE + SUCCESS:
      return {
        ...state,
        userFiles: {
          ...state.userFiles,
          ...payload,
        },
      }
    case FETCH_USER_FILES:
      return {
        ...state,
        userFiles: {
          ...state.userFiles,
          ...payload,
        },
      }
    case FETCH_SEARCH_USER_FILES:
      return {
        ...state,
        userFiles: {
          ...payload,
        },
      }
    case ADD_FILES:
      return {
        ...state,
        userFiles: {
          ...state.userFiles,
          ...payload,
        },
      }
    case REMOVE_FILE:
      return {
        ...state,
        userFiles: removeFile(state.userFiles, payload),
      }
    case SET_IS_ALL_FILES_FETCHED:
      return {
        ...state,
        isAllFilesFetched: payload,
      }
    case ADD_FILE_TO_PAGE_FILES:
      return {
        ...state,
        pageFiles: {
          ...state.pageFiles,
          [payload.id]: { ...payload },
        },
      }
    case SET_FILES: {
      return {
        ...state,
        ...payload,
      }
    }
    default:
      return state
  }
}

const getFilteredByType = (files, type) => {
  const filteredUserFiles = {}
  Object.keys(files)
    .filter(fileId => files[fileId].type.length && files[fileId].type === type)
    .forEach(fileId => {
      filteredUserFiles[fileId] = files[fileId]
    })

  return filteredUserFiles
}

export const getUserFilesByType = ({ userFiles }, type) =>
  getFilteredByType(userFiles, type)

export const getUserFiles = ({ userFiles }) => userFiles

export const getIsAllFilesFetched = ({ isAllFilesFetched }) => isAllFilesFetched

export const getFileById = ({ pageFiles, userFiles }, fileId) => {
  if (!fileId) {
    return null
  }

  return pageFiles[fileId] || userFiles[fileId]
}

export const getFilesByIds = (files, filesIds, asObject = true) => {
  if (asObject) {
    return filesIds.reduce(
      (prev, id) => ({
        ...prev,
        [id]: getFileById(files, id),
      }),
      {},
    )
  }

  return filesIds.map(id => getFileById(files, id))
}

export const selectors = {
  getFileById,
  getFilesByIds,
}
