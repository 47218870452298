import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { Range } from '../components'
import MobileIconUi from '../styles/ui/MobileIconUi'

function FontSize(props) {
  const { isMobile } = useDeviceModeContext()
  const update = value =>
    isMobile ? props.mobileUpdate(value) : props.update(value)

  const { fontSize, mobileFontSize, labelText } = props

  const currentFontSize = isMobile ? mobileFontSize : fontSize

  return (
    <Range
      labelText={labelText}
      labelIcon={isMobile && <MobileIconUi />}
      value={parseInt(currentFontSize, 0)}
      update={update}
      min={8}
    />
  )
}

FontSize.propTypes = {
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobileFontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  update: PropTypes.func.isRequired,
  mobileUpdate: PropTypes.func.isRequired,
  labelText: PropTypes.string,
}

FontSize.defaultProps = {
  fontSize: null,
  mobileFontSize: null,
  labelText: 'settings_styles.font_size.label',
}

export default memo(FontSize)
