import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { slugs } from 'common/constants/inputTypes'
import { pageTypes } from 'common/constants/pageTypes'
import * as types from 'common/types'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import { getIdNameListWithEmailAndNameFirst } from 'client/reducers/inputTypesReducer'
import { getPageType } from 'client/reducers/pageReducer'
import { Select, Input } from '../components'
import { Checkbox } from '../components'
import IconPickerWithRemoveButton from '../components/IconPickerWithRemoveButton'
import { FontSize, DeviceAppearance, Attributes } from '../options'
import GroupTitle from '../styleComponents/GroupTitle'
import {
  BorderStyle,
  MarginsStyle,
  CornerStyle,
  ShadowStyle,
  TextAlignStyle,
} from '../styles'
import LineHeight from '../styles/LineHeight'

const InputSettings = props => {
  const {
    entity,
    updateOptions,
    updateStyle,
    updateMobileStyle,
    updateOption,
    updateMobileOption,
    update,
    inputTypes,
    overlayVisible,
    toggleOverlay,
    pageType,
  } = props
  const updateInputType = newSlug => {
    let slug = ''
    let placeholder = ''
    let inputType = null
    let optional = false
    if (newSlug) {
      const inputTypeObj = inputTypes.find(obj => obj.slug === newSlug)
      slug = inputTypeObj.slug
      inputType = inputTypeObj.type
      placeholder = inputTypeObj.name
    }
    optional = newSlug === slugs.EMAIL ? undefined : entity.options.optional

    if (newSlug === slugs.COMPANY_NAME || newSlug === slugs.TAX_NUMBER) {
      optional = true
    }

    updateOptions({
      inputType,
      placeholder,
      slug,
      optional,
    })
  }

  let preparedInputTypes = {}
  let isLoginPage = false
  let isPasswordType = false
  inputTypes.forEach(inputType => {
    preparedInputTypes[inputType.slug] = inputType.name
  })

  if (pageType === pageTypes.customLogin) {
    isLoginPage = true
    if (entity.options.slug === slugs.PASSWORD) {
      isPasswordType = true
    }
  }
  const isMandatoryType = entity.options.slug === slugs.EMAIL
  const isOptionalType =
    entity.options.slug === slugs.TAX_NUMBER ||
    entity.options.slug === slugs.COMPANY_NAME

  const isCountryType = entity.options.slug === slugs.COUNTRY
  const isOptionalSettingAvailable =
    !isLoginPage && !isMandatoryType && !isOptionalType

  return (
    <React.Fragment>
      {!isLoginPage && (
        <Select
          update={updateInputType}
          labelText="Input type"
          value={entity.options.slug}
          firstOption="Select a type"
          choiceList={preparedInputTypes}
          needTranslate={false}
        />
      )}
      {!isCountryType && (
        <Input
          labelText="placeholder"
          value={entity.options.placeholder}
          update={updateOption('placeholder')}
        />
      )}
      <TextAlignStyle
        textAlign={entity.styles.textAlign}
        mobileTextAlign={entity.mobileStyles.textAlign}
        update={updateStyle('textAlign')}
        mobileUpdate={updateMobileStyle('textAlign')}
      />
      <ColorPicker
        update={updateOption('placeholderColor')}
        mobileUpdate={updateMobileOption('placeholderColor')}
        color={entity.options.placeholderColor}
        mobileColor={
          entity.mobileOptions.placeholderColor ||
          entity.options.placeholderColor
        }
        label="entity_settings.field.placeholder_color"
      />
      {isOptionalSettingAvailable && (
        <Checkbox
          labelText="entity_settings.field.optional"
          helpMessage="entity_settings.field.optional_popover"
          value={entity.options.optional}
          update={updateOption('optional')}
        />
      )}
      <GroupTitle>typography</GroupTitle>
      <FontSize
        update={updateStyle('fontSize', 'px')}
        mobileUpdate={updateMobileStyle('fontSize', 'px')}
        fontSize={entity.styles.fontSize}
        mobileFontSize={entity.mobileStyles.fontSize}
      />
      <LineHeight
        lineHeight={entity.styles.lineHeight}
        mobileLineHeight={entity.mobileStyles.lineHeight}
        update={updateStyle('lineHeight', 'px')}
        mobileUpdate={updateMobileStyle('lineHeight', 'px')}
      />
      {!isCountryType && !isPasswordType && (
        <IconPickerWithRemoveButton
          labelText="entity_options.icon_picker.title"
          value={entity.options.iconClassName}
          update={updateOption('iconClassName')}
          searchPlaceholder="settings.components.icon_picker.placeholder"
        />
      )}
      <GroupTitle>color</GroupTitle>
      <ColorPicker
        update={updateStyle('backgroundColor')}
        mobileUpdate={updateMobileStyle('backgroundColor')}
        color={entity.styles.backgroundColor}
        mobileColor={entity.mobileStyles.backgroundColor}
        label="bg color"
      />
      <ColorPicker
        update={updateStyle('color')}
        mobileUpdate={updateMobileStyle('color')}
        color={entity.styles.color}
        mobileColor={entity.mobileStyles.color}
        label="entity_settings.field.input_value_color"
      />
      <MarginsStyle
        marginTop={entity.styles.marginTop}
        marginRight={entity.styles.marginRight}
        marginBottom={entity.styles.marginBottom}
        marginLeft={entity.styles.marginLeft}
        mobileMarginTop={entity.mobileStyles.marginTop}
        mobileMarginRight={entity.mobileStyles.marginRight}
        mobileMarginBottom={entity.mobileStyles.marginBottom}
        mobileMarginLeft={entity.mobileStyles.marginLeft}
        update={updateStyle}
        mobileUpdate={updateMobileStyle}
      />
      <CornerStyle
        topLeft={entity.styles.borderTopLeftRadius}
        topRight={entity.styles.borderTopRightRadius}
        bottomRight={entity.styles.borderBottomRightRadius}
        bottomLeft={entity.styles.borderBottomLeftRadius}
        mobileTopLeft={entity.mobileStyles.borderTopLeftRadius}
        mobileTopRight={entity.mobileStyles.borderTopRightRadius}
        mobileBottomLeft={entity.mobileStyles.borderBottomLeftRadius}
        mobileBottomRight={entity.mobileStyles.borderBottomRightRadius}
        update={updateStyle}
        mobileUpdate={updateMobileStyle}
      />
      <ShadowStyle
        shadow={entity.styles.boxShadow}
        mobileShadow={entity.mobileStyles.boxShadow}
        update={updateStyle('boxShadow')}
        mobileUpdate={updateMobileStyle('boxShadow')}
      />
      <BorderStyle
        updateOption={updateOption}
        updateMobileOption={updateMobileOption}
        updateStyle={updateStyle}
        updateMobileStyle={updateMobileStyle}
        type={entity.options.borderType}
        style={entity.styles.borderStyle}
        width={entity.styles.borderWidth}
        color={entity.styles.borderColor}
        mobileType={entity.mobileOptions.borderType}
        mobileStyle={entity.mobileStyles.borderStyle}
        mobileWidth={entity.mobileStyles.borderWidth}
        mobileColor={entity.mobileStyles.borderColor}
        toggleOverlay={toggleOverlay}
        overlayVisible={overlayVisible}
      />
      <DeviceAppearance update={update} entity={entity} />
      <Attributes update={updateOption} entity={entity} />
    </React.Fragment>
  )
}

InputSettings.propTypes = {
  toggleOverlay: PropTypes.func.isRequired,
  updateOptions: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  updateStyle: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  updateMobileOption: PropTypes.func.isRequired,
  entity: types.entity.isRequired,
  overlayVisible: PropTypes.bool.isRequired,
  inputTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const mapStateToProps = ({ inputTypes, page: { present: presentPage } }) => ({
  inputTypes: getIdNameListWithEmailAndNameFirst(inputTypes),
  pageType: getPageType(presentPage),
})

export default connect(mapStateToProps, null)(InputSettings)
