import React from 'react'
import CommonFaq, { FaqItem } from 'common/components/entities/Faq'
import { EntityTypeEnum } from 'common/enums/entityTypeEnum'
import FaqInterface, {
  FaqItemInterface,
} from 'common/types/entities/FaqInterface'
import BaseEntity from 'client/components/core/BaseEntity/BaseEntityNew'
import ChildrenEntities from 'client/components/core/ChildrenEntities'
import {
  managementSelectors,
  pageSelectors,
  useManagement,
  usePage,
} from 'client/store/index'
import {
  generateHtmlAttrId,
  generateId,
} from '../../utils/createStructureUtils'

type FaqProps = {
  entity: FaqInterface
  isMoveDownAllowed: boolean
  isMoveUpAllowed: boolean
}

function Faq({ entity, isMoveDownAllowed, isMoveUpAllowed }: FaqProps) {
  const activeIndex = useManagement(management =>
    managementSelectors.getEntityInnerItemActiveIndex(management, entity.id),
  )
  const faqItems = usePage(page =>
    pageSelectors.getChildrenEntities(page, entity.childIds),
  ) as FaqItemInterface[]

  return (
    <BaseEntity
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <CommonFaq
        mobilePadding={entity.mobilePadding}
        padding={entity.padding}
        htmlAttrId={entity.htmlAttrId}
        spaceBetweenItems={entity.spaceBetweenItems}
      >
        {faqItems.map((faqItem, index) => (
          <FaqItem
            key={faqItem.id}
            showIcons={entity.showIcons}
            mobileShowIcons={entity.mobileShowIcons}
            itemBorderRadius={entity.itemBorderRadius}
            itemPadding={entity.itemPadding}
            mobileItemPadding={entity.mobileItemPadding}
            backgroundColor={entity.backgroundColor}
            mobileBackgroundColor={entity.mobileBackgroundColor}
            titleColor={entity.titleColor}
            mobileTitleColor={entity.mobileTitleColor}
            fontSize={entity.titleFontSize}
            mobileFontSize={entity.mobileTitleFontSize}
            fontFamily={entity.titleFontFamily}
            fontStyle={entity.titleFontStyle}
            fontWeight={entity.titleFontWeight}
            title={faqItem.title}
            expanded={activeIndex === index}
            iconClassName={entity.iconClassName}
            closedIconClassName={entity.closedIconClassName}
            iconColor={entity.iconColor}
            mobileIconColor={entity.mobileIconColor}
            boxShadow={entity.boxShadow}
            mobileBoxShadow={entity.mobileBoxShadow}
          >
            <ChildrenEntities
              id={faqItem.id}
              isReadOnly={faqItem.isReadOnly}
              type={faqItem.type}
              childIds={faqItem.childIds}
              placeholderText="entities.faq.empty_dropbox_text"
            />
          </FaqItem>
        ))}
      </CommonFaq>
    </BaseEntity>
  )
}

export function createFaq(
  parentId: string,
  masterBlockId?: string,
): FaqInterface {
  return {
    id: generateId(),
    type: EntityTypeEnum.Faq,
    childIds: [],
    parentId: parentId,
    masterBlockId: masterBlockId,
    showIcons: true,
    mobileShowIcons: true,
    iconClassName: 'fas fa-chevron-circle-up',
    iconColor: 'rgba(24, 24, 24, 1)',
    backgroundColor: 'rgba(241, 244, 248, 1)',
    titleColor: 'rgba(24, 24, 24, 1)',
    titleFontSize: 16,
    mobileTitleFontSize: 16,
    spaceBetweenItems: 10,
    itemBorderRadius: 5,
    padding: {
      paddingTop: 10,
      paddingRight: 10,
      paddingBottom: 10,
      paddingLeft: 10,
    },
    mobilePadding: {
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
    },
    itemPadding: {
      paddingTop: 15,
      paddingRight: 15,
      paddingBottom: 15,
      paddingLeft: 20,
    },
    mobileItemPadding: {
      paddingTop: 10,
      paddingRight: 10,
      paddingBottom: 10,
      paddingLeft: 15,
    },
    margin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    mobileMargin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    appearance: {
      desktop: true,
      mobile: true,
    },
    htmlAttrId: generateHtmlAttrId(EntityTypeEnum.Faq),
  }
}

export function createFaqAnswer(
  parentId: string,
  masterBlockId?: string,
  creationItemNumber = 1,
): FaqItemInterface {
  return {
    id: generateId(),
    type: EntityTypeEnum.FaqItem,
    creationItemNumber: creationItemNumber,
    title: '',
    parentId: parentId,
    masterBlockId: masterBlockId,
    childIds: [],
  }
}

export function getFaqFontProperties(entity: FaqInterface) {
  return entity.titleFontFamily
    ? [
        {
          fontFamily: entity.titleFontFamily,
          fontStyle: entity.titleFontStyle,
          fontWeight: entity.titleFontWeight,
        },
      ]
    : []
}

export function getFaqCustomFontProperties(entity: FaqInterface) {
  return entity.titleFontFamily
    ? [
        {
          fontFamily: entity.titleFontFamily,
          fontFileId: entity.fontFileId,
        },
      ]
    : []
}
export default Faq
