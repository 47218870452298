import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { localeValues } from 'common/constants/localeTypes'
import URLSearchParams from 'url-search-params'
import LanguageSwitcherItemUi from './ui/LanguageSwitcherItemUi'
import LanguageSwitcherUi from './ui/LanguageSwitcherUi'

const LanguageSwitcher = ({ items }) => {
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search)
    const debug = searchParams.get('s.io_debug')
    if (debug) {
      setVisible(true)
    }
  }, [])

  const { i18n } = useTranslation()
  const changeLanguage = lng => () => {
    i18n.changeLanguage(lng)
  }

  if (!visible) {
    return <LanguageSwitcherUi />
  }

  return (
    <LanguageSwitcherUi>
      {items.map(item => (
        <LanguageSwitcherItemUi
          key={item}
          onClick={changeLanguage(item)}
          isActive={item === i18n.language}
        >
          {item}
        </LanguageSwitcherItemUi>
      ))}
    </LanguageSwitcherUi>
  )
}

LanguageSwitcher.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
}

LanguageSwitcher.defaultProps = {
  items: Object.keys(localeValues),
}

export default LanguageSwitcher
